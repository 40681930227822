import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Typography, Grid } from "@mui/material";
import Uppy from "@uppy/core";
import XHRUpload from "@uppy/xhr-upload";
import DragDrop from "@uppy/drop-target";
import ThumbnailGenerator from "@uppy/thumbnail-generator";
import { size, toArray } from "lodash";

import Button from "common/Button";
import { formatFile, getPercentage } from "../../utility";
import config from "../../config";
import FileItem from "./FileItem";

import "@uppy/core/dist/style.css";
import "@uppy/drag-drop/dist/style.css";
import "@uppy/dashboard/dist/style.css";
import "./uploader-styles.scss";

const SingleUploader = ({
  entity,
  bundle,
  field,
  onUploadSuccess,
  onUploadStart,
  onUploadFail,
  onRemove,
  existingFile,
  label,
  inputLabel,
  onFileAdded,
  allowedFileTypes,
  imageFileTypes,
  maxFileSize,
}) => {
  const [filesState, _setFiles] = useState({});
  const [errorMessage, setErrorMessage] = useState(null);
  const auth = useSelector((state) => state.auth.token);
  const csrf = useSelector((state) => state.auth.csrf);
  const filesRef = useRef(filesState);

  const setFiles = (data) => {
    filesRef.current = data;
    _setFiles(data);
  };

  const _fileTypes = (imageFileTypes ?
    [
      ".jpg",
      ".jpeg",
      ".gif",
      ".png",
      ".heic",
    ] : (allowedFileTypes ? allowedFileTypes : [
      "image/*",
      ".jpg",
      ".jpeg",
      ".gif",
      ".png",
      ".svg",
      ".pdf",
      ".doc",
      ".docx",
      ".ppt",
      ".pptx",
      ".xls",
      ".xlsx",
      ".csv",
      ".zip",
      ".tmproject",
      ".esx",
      ".bdata",
      ".xml",
      ".webloc",
      ".xlsb",
      ".heic",
    ])
  );

  useEffect(() => {
    if (existingFile) {
      const files = existingFile.map((file) => ({
        ...file,
        id: file.fid,
        progress: {},
        meta: { name: file.filename },
        data: {
          size: file.filesize,
        },
      }));
      const data = {};
      files.forEach((f) => {
        data[f.id] = f;
      });

      setFiles(data);
    }
  }, []); //eslint-disable-line

  const [uppy] = useState(() => {

    return new Uppy({
      autoProceed: true,
      restrictions: {
        allowedFileTypes: _fileTypes,
        maxNumberOfFiles: 1,
        maxFileSize: maxFileSize ? maxFileSize : 10000000,
      },
      onBeforeFileAdded: (currentFile, files) => {
      //  Strip any Microsoft hyphens that may have snuck in.
        const newName = currentFile.name.replace(/–/g, '_');
        const modifiedFile = {
          ...currentFile,
          name: newName,
        }
        return modifiedFile
      },
    })
      .use(XHRUpload, {
        formData: false,
        headers: (file) => ({
          "X-CSRF-Token": csrf,
          "Content-Type": "application/octet-stream",
          "Content-Disposition": `file; filename="${encodeURIComponent(file.name)}"`,
          Authorization: `Bearer ${auth}`,
        }),
        endpoint: `${config.api_url}/file/upload/${entity}/${bundle}/${field}`,
      })
      .use(DragDrop, {
        target: document.body,
      })
      .use(ThumbnailGenerator, {
        thumbnailWidth: 200,
        waitForThumbnailsBeforeUpload: true,
      })
      .on("file-added", (file) => {
        const files = filesRef.current;
        setFiles({ ...files, [file.id]: file });
        if (onFileAdded) {
          onFileAdded([file]);
        }
      })
      .on("thumbnail:generated", (file, preview) => {
        const files = filesRef.current;
        setFiles({ ...files, [file.id]: file });

        setErrorMessage(null);
      })
      .on("upload", (uploadID, files ) => {
        const _files = filesRef.current;
        const currentFiles = { ..._files };
        files.forEach((file) => currentFiles[file.id].progress.uploadStarted = true);

        onUploadStart();
        setFiles({ ...currentFiles });
        setErrorMessage(null);
      })
      .on("upload-progress", (file, progress) => {
        const files = filesRef.current;
        const percentage = getPercentage(
          progress.bytesUploaded,
          progress.bytesTotal
        );
        files[file.id].progress.bytesTotal = progress.bytesTotal;
        files[file.id].progress.bytesUploaded = progress.bytesUploaded;
        files[file.id].progress.percentage = percentage;
        setFiles({ ...files });
      })
      .on("upload-success", (file, response) => {
        const files = filesRef.current;
        const fileData = formatFile({ data: response.body });
        file.progress.uploadComplete = true;
        file.fileData = fileData;
        setFiles({ ...files, [file.id]: file });

        onUploadSuccess(fileData.fid);
      })
      .on("upload-error", (file, error, response) => {
        const files = filesRef.current;
        file.error = error;

        onUploadFail();
        setFiles({ ...files, [file.id]: file });
        setErrorMessage(error.message);
      })
      .on("upload-retry", (fileID) => {
        const files = filesRef.current;
        files[fileID].progress.uploadStarted = true;
        files[fileID].error = null;
        files[fileID].progress.bytesUploaded = 0;
        files[fileID].progress.percentage = 0;

        onUploadStart();
        setFiles({ ...files });
        setErrorMessage(null);
      })
      .on('restriction-failed', (file, error) => {
        setErrorMessage(error.message)
      });
  });

  const handleRetryUpload = (id) => {
    uppy.retryUpload(id);
  };

  const handleRemove = (id) => {
    const files = filesRef.current;
    delete files[id];

    onRemove();
    setFiles({ ...files });
    uppy.removeFile(id);
  };

  return (
    <div id="drop-area" style={{ position: "relative", padding: "1.25rem 0" }}>
      {inputLabel && (
        <Typography variant="subtitle1" style={{ marginBottom: "1rem" }}>
          {inputLabel}
        </Typography>
      )}
      {size(filesState) > 0 ? (
        <Grid container spacing={1}>
          {toArray(filesState).map((file) => (
            <Grid item xxs={12} sm={6} md={4}>
              <FileItem
                file={file}
                acquirers={[]}
                retryUpload={() => handleRetryUpload(file.id)}
                onRemove={() => handleRemove(file.id)}
              />
            </Grid>
          ))}
        </Grid>
      ) : (
        <>
          <input
            accept={_fileTypes.join(', ')}
            style={{ display: "none" }}
            id="contained-button-file"
            type="file"
            onChange={(e) => {
              const files = Array.from(e.target.files);

              files.forEach((file) => {
                try {
                  uppy.addFile({
                    source: "file input",
                    name: file.name,
                    type: file.type,
                    data: file,
                  });
                } catch (err) {
                  if (err.isRestriction) {
                    console.log("Restriction error:", err);
                  } else {
                    console.error(err);
                  }
                }
              });
            }}
          />
          <label htmlFor="contained-button-file">
            <Button
              variant="contained"
              color="tertiary"
              disableElevation
              size="small"
              component="span"
            >
              {label ? label : "Upload"}
            </Button>
          </label>
        </>
      )}
      {errorMessage !== null &&
        <div className="uppy-error-message-wrapper"><div className="message">{errorMessage}</div></div>
      }
    </div>
  );
};

SingleUploader.propTypes = {};

export default SingleUploader;
