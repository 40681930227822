import { getRoleType, hasPermission } from "../../utility";
import { useSelector } from "react-redux";

const Guard = ({ intendedRoles, intendedRoleTypes, customOverrideName='default', children }) => {
  const user = useSelector((state) => state.auth.user.data);
  const roles = user?._processed?.roles ? user._processed.roles : [];
  const divisionProcessed = useSelector((state) => state.job.division.data._processed);

  const hasAccess = intendedRoleTypes ? hasPermission([getRoleType(roles)], intendedRoleTypes) : hasPermission(roles, intendedRoles);

  const overrides = {
    canManageEntryVisibility: () => { return Boolean(divisionProcessed?.ds_manage_entry_visibility)},
    debugFalse: () => {return false},
    default: () => { return true },
  }

  if ((!hasAccess) && !(overrides[customOverrideName]())) return null;

  return children;
};

Guard.propTypes = {};

export default Guard;
