import {
  createSlice,
  createEntityAdapter,
  createAsyncThunk,
  createDraftSafeSelector,
} from "@reduxjs/toolkit";
import axios from "axios";
import querystring from "querystring";

import { formatField } from "../../utility";
import { tokenConfig } from "../../actions/authActions";
import config from "../../config";
import { formatMemberFields, formatInsuranceAgent } from "./utils";

import { setAlert } from "features/Alert/alertSlice";

const getQueryParams = (params) => {
  return `?${
    typeof params === "string"
      ? params.substring(1)
      : querystring.stringify(params)
  }`;
};

const namespace = "member";

export const fetchMember = createAsyncThunk(
  `${namespace}/fetchMember`,
  async (name, { getState, signal, rejectWithValue }) => {
    try {
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(`${config.api_url}/member/${name}`, {
        ...tokenConfig(getState),
        cancelToken: source.token,
      });

      const member = formatMemberFields(response.data);
      const afterHoursPocsIds = member.field_after_hours_pocs;
      const primaryPocIds = member.field_primary_poc;
      const secondaryPocIds = member.field_secondary_pocs;

      return {
        member: {
          ...member,
          primaryPocIds: primaryPocIds.map((u) => u.uid),
          afterHoursPocsIds: afterHoursPocsIds.map((u) => u.uid),
          secondaryPocIds: secondaryPocIds.map((u) => u.uid),
        },
        users: [...primaryPocIds, ...secondaryPocIds, ...afterHoursPocsIds],
      };
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchMemberByNid = createAsyncThunk(
  `${namespace}/fetchMemberByNid`,
  async (id, { getState, signal, rejectWithValue }) => {
    try {
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(`${config.api_url}/node/${id}`, {
        ...tokenConfig(getState),
        cancelToken: source.token,
      });

      const member = formatMemberFields(response.data);
      const afterHoursPocsIds = member.field_after_hours_pocs;
      const primaryPocIds = member.field_primary_poc;
      const secondaryPocIds = member.field_secondary_pocs;

      return {
        member: {
          ...member,
          primaryPocIds: primaryPocIds.map((u) => u.uid),
          afterHoursPocsIds: afterHoursPocsIds.map((u) => u.uid),
          secondaryPocIds: secondaryPocIds.map((u) => u.uid),
        },
        users: [...primaryPocIds, ...secondaryPocIds, ...afterHoursPocsIds],
      };
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchMemberInsuranceAgents = createAsyncThunk(
  `${namespace}/fetchMemberInsuranceAgents`,
  async (id, { getState, signal, rejectWithValue }) => {
    try {
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(
        `${config.api_url}/entity/node/field_member_insurance_agents/${id}`,
        {
          ...tokenConfig(getState),
          cancelToken: source.token,
        }
      );

      const agents = response?.data?.field_member_insurance_agents
        ? response?.data?.field_member_insurance_agents.map((agent) =>
            formatInsuranceAgent(agent)
          )
        : [];

      return agents;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchMemberJobsForMap = createAsyncThunk(
  `${namespace}/fetchMemberJobsForMap`,
  async (id, { getState, signal, rejectWithValue }) => {
    try {
      const queryparams = getQueryParams({
        "filter[member_nid]": id,
        clustering_toggle: "off",
      });
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(
        `${config.api_url}/rest/map/jobs${queryparams}`,
        {
          ...tokenConfig(getState),
          cancelToken: source.token,
        }
      );

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const postMember = createAsyncThunk(
  `${namespace}/postMember`,
  async (params, { getState, rejectWithValue, dispatch }) => {
    try {
      const response = await axios.post(
        `${config.api_url}/node`,
        {
          _links: {
            type: {
              href: `${config.api_url}/rest/type/node/member`,
            },
          },
          ...params,
        },
        tokenConfig(getState)
      );

      return formatMemberFields(response.data);
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      dispatch(
        setAlert({
          show: true,
          kind: "negative",
          msg: `Error creating Provider: ${error.response.data?.message}`,
        })
      );
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const postMemberInsuranceAgent = createAsyncThunk(
  `${namespace}/postMemberInsuranceAgent`,
  async ({ id, params }, { getState, rejectWithValue, dispatch }) => {
    try {
      const response = await axios.post(
        `${config.api_url}/node`,
        {
          _links: {
            type: {
              href: `${config.api_url}/rest/type/node/member_insurance_agent`,
            },
          },
          _meta: {
            member_nid: id,
          },
          ...params,
        },
        tokenConfig(getState)
      );

      dispatch(
        setAlert({
          show: true,
          kind: "positive",
          msg: `Successfully added Agent`,
        })
      );

      return formatInsuranceAgent(response.data);
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      dispatch(
        setAlert({
          show: true,
          kind: "negative",
          msg: `Error creating Agent: ${error.response.data?.message}`,
        })
      );
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const patchMemberInsuranceAgent = createAsyncThunk(
  `${namespace}/patchMemberInsuranceAgent`,
  async ({ id, params }, { getState, rejectWithValue, dispatch }) => {
    try {
      const response = await axios.patch(
        `${config.api_url}/node/${id}`,
        {
          _links: {
            type: {
              href: `${config.api_url}/rest/type/node/member_insurance_agent`,
            },
          },
          ...params,
        },
        tokenConfig(getState)
      );

      dispatch(
        setAlert({
          show: true,
          kind: "positive",
          msg: `Successfully updated Agent`,
        })
      );

      return formatInsuranceAgent(response.data);
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      dispatch(
        setAlert({
          show: true,
          kind: "negative",
          msg: `Error updating Agent: ${error.response.data?.message}`,
        })
      );
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const patchMember = createAsyncThunk(
  `${namespace}/patchMember`,
  async ({ id, params }, { getState, rejectWithValue, dispatch }) => {
    try {
      const response = await axios.patch(
        `${config.api_url}/node/${id}`,
        {
          _links: {
            type: {
              href: `${config.api_url}/rest/type/node/member`,
            },
          },
          ...params,
        },
        tokenConfig(getState)
      );

      dispatch(
        setAlert({
          show: true,
          kind: "positive",
          msg: "Successfully updated Provider",
        })
      );
      return formatMemberFields(response.data);
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      dispatch(
        setAlert({
          show: true,
          kind: "negative",
          msg: `Error updating Provider: ${error.response.data?.message}`,
        })
      );
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteMemberInsuranceAgent = createAsyncThunk(
  `${namespace}/deleteMemberInsuranceAgent`,
  async ({ id }, { getState, rejectWithValue, dispatch }) => {
    try {
      const response = await axios.delete(
        `${config.api_url}/node/${id}`,
        tokenConfig(getState)
      );

      dispatch(
        setAlert({
          show: true,
          kind: "positive",
          msg: `Successfully removed Agent`,
        })
      );

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      dispatch(
        setAlert({
          show: true,
          kind: "negative",
          msg: `Error removing Agent: ${error.response.data?.message}`,
        })
      );
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

const usersAdapter = createEntityAdapter({
  selectId: (user) => user.uid,
});

const insuranceAgentsAdapter = createEntityAdapter({
  selectId: (agent) => agent.nid,
});

const memberSlice = createSlice({
  name: namespace,
  initialState: {
    loading: true,
    error: null,
    data: {},
    users: usersAdapter.getInitialState(),
    insuranceAgents: insuranceAgentsAdapter.getInitialState({
      loading: false,
    }),
  },
  reducers: {
    setMemberData: (state, { payload: member }) => {
      state.loading = false;
      state.data = member;
      state.error = null;
    },
    clearMember: (state) => {
      state.loading = true;
      state.error = null;
      state.data = {};
    },
  },
  extraReducers: {
    [fetchMember.pending](state) {
      state.error = null;
      state.loading = true;
    },
    [fetchMember.fulfilled](state, { payload: { member, users } }) {
      state.loading = false;
      state.data = member;
      usersAdapter.setAll(state.users, users);
    },
    [fetchMember.rejected](state, action) {
      if (!action.meta.aborted) {
        state.loading = false;
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [fetchMemberByNid.pending](state) {
      state.error = null;
      state.loading = true;
    },
    [fetchMemberByNid.fulfilled](state, { payload: { member, users } }) {
      state.loading = false;
      state.data = member;
      usersAdapter.setAll(state.users, users);
    },
    [fetchMemberByNid.rejected](state, action) {
      if (!action.meta.aborted) {
        state.loading = false;
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      }
    },
    [fetchMemberInsuranceAgents.pending](state) {
      state.insuranceAgents.error = null;
      state.insuranceAgents.loading = true;
    },
    [fetchMemberInsuranceAgents.fulfilled](state, { payload: agents }) {
      state.insuranceAgents.loading = false;
      insuranceAgentsAdapter.setAll(state.insuranceAgents, agents);
    },
    [fetchMemberInsuranceAgents.rejected](state, action) {
      if (!action.meta.aborted) {
        state.insuranceAgents.loading = false;
        if (action.payload) {
          state.insuranceAgents.error = action.payload.message;
        } else {
          state.insuranceAgents.error = action.error.message;
        }
      }
    },
    [postMember.fulfilled](state, { payload: member }) {
      state.data = member;
    },
    [postMember.rejected](state, action) {
      if (action.payload) {
        state.error = action.payload.message;
      } else {
        state.error = action.error.message;
      }
    },
    [patchMember.pending](state, action) {
      const { params, users } = action.meta.arg;
      const tempParams = { ...params };

      Object.keys(tempParams).forEach((key) => {
        if (
          key !== "field_division_types" &&
          key !== "field_jd_types" &&
          key !== "field_certifications" &&
          key !== "field_counties_owned" &&
          key !== "field_counties_served" &&
          key !== "field_phoenix_clients" &&
          key !== "field_managing_phoenix_clients" &&
          key !== "field_national_contracts"
        ) {
          tempParams[key] = formatField(tempParams, key);
        }
      });

      let userParams = {};
      if (users) {
        userParams = users;
      }

      state.data = {
        ...state.data,
        ...tempParams,
        ...userParams,
      };
    },
    [patchMember.fulfilled](state, { payload: member }) {
      state.data = member;
    },
    [patchMember.rejected](state, action) {
      const { member } = action.meta.arg;

      state.data = member;
      // if (action.payload) {
      //   state.error = action.payload.message;
      // } else {
      //   state.error = action.error.message;
      // }
    },
    [postMemberInsuranceAgent.fulfilled](state, { payload: member }) {
      insuranceAgentsAdapter.addOne(state.insuranceAgents, member);
    },
    [postMemberInsuranceAgent.rejected](state, action) {
      if (action.payload) {
        state.insuranceAgents.error = action.payload.message;
      } else {
        state.insuranceAgents.error = action.error.message;
      }
    },
    [patchMemberInsuranceAgent.fulfilled](state, { meta, payload: member }) {
      const { id } = meta.arg;
      insuranceAgentsAdapter.updateOne(state.insuranceAgents, {
        id,
        changes: member,
      });
    },
    [patchMemberInsuranceAgent.rejected](state, action) {
      if (action.payload) {
        state.insuranceAgents.error = action.payload.message;
      } else {
        state.insuranceAgents.error = action.error.message;
      }
    },
    [deleteMemberInsuranceAgent.pending](state, action) {
      const { id } = action.meta.arg;

      insuranceAgentsAdapter.removeOne(state.insuranceAgents, id);
    },
    [deleteMemberInsuranceAgent.rejected](state, action) {
      const { agent } = action.meta.arg;
      insuranceAgentsAdapter.addOne(state.insuranceAgents, agent);
      if (action.payload) {
        state.insuranceAgents.error = action.payload.message;
      } else {
        state.insuranceAgents.error = action.error.message;
      }
    },
  },
});

export const insuranceAgentsSelectors = insuranceAgentsAdapter.getSelectors(
  (state) => state.member.insuranceAgents
);

// Custom selectors
const selectSelf = (state) => state;
export const getMemberDataSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.member.data
);

export const getMemberLoadingSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.member.loading
);

export const getMemberErrorSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.member.error
);

export const getMemberInsuranceAgentsLoadingSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.member.insuranceAgents.loading
);

export const getMemberInsuranceAgentsErrorSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.member.insuranceAgents.error
);

export const { setMemberData, clearMember } = memberSlice.actions;

export default memberSlice.reducer;
