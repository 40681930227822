import React, { useRef, useCallback, useEffect, useState } from "react";
import { MultiUploader } from "common/Uploader";
import { TextField } from "@mui/material";
import config from "config";
import { useDispatch } from "react-redux";
import { postElocalVerifyHash } from "../elocalSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { DefaultLoader } from "common/Loader";
import { setAlert } from "features/Alert/alertSlice";
import Empty from "common/Empty";
import { GiArchiveResearch } from "react-icons/gi";
import { size } from "lodash";
import Card from "common/Card";
import { Form } from "features/Job/forms/AddDocument";
import FileToolbar from "features/Job/components/FileToolbar";
import Alert from "common/Alert";
import ReCAPTCHA from "react-google-recaptcha";

const Upload = ({ hash, children }) => {
  const dispatch = useDispatch();
  const recaptchaRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState(null);
  const [references, setReferences] = useState(null);
  const [formData, setFormData] = useState({
    customer_name: "",
    recaptcha_token: "",
  });
  const [uploading, setUploading] = useState(false);
  const [message, setMessage] = useState(null);
  const [selected, setSelected] = useState([]);
  const [allSelectable, setAllSelectable] = useState([]);
  const [allSelected, setAllSelected] = useState(false);

  const handleVerifyHash = useCallback(
    async (hash, ignoreLoading) => {
      if(!ignoreLoading){
        setLoading(true);
      }
      try {
        const resultAction = await dispatch(postElocalVerifyHash({ hash }));
        const payload = unwrapResult(resultAction);
        setToken(payload.token);
        setReferences(payload.references);
      } catch (err) {
        dispatch(
          setAlert({
            show: true,
            kind: "negative",
            msg: "There was an error verifying the hash",
          })
        );
        console.error(err);
      }
      if(!ignoreLoading){
        setLoading(false);
      }
    },
    [dispatch]
  );

  useEffect(() => {
    if (hash) {
      handleVerifyHash(hash);
    }
  }, [hash, handleVerifyHash]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSelect = useCallback(
    (id) => {
      const index = selected.indexOf(id);
      const tempSelected = [...selected];
      if (index > -1) {
        tempSelected.splice(index, 1);
        if(tempSelected.length !== selected.length){
          setAllSelected(false);
        }
      } else {
        tempSelected.push(id);
      }

      setSelected(tempSelected);
    },
    [selected]
  );

  const handleSelectAll = useCallback(
    () => {
      if(!allSelected){
        setSelected(allSelectable);
        setAllSelected(true);
      }
      else{
        setSelected([]);
        setAllSelected(false);
      }
    },
    [allSelectable, allSelected]
  );

  const metaFields = {
    token,
    customer_name: formData.customer_name,
    recaptcha_token: formData.recaptcha_token,
  };

  return loading ? (
    <DefaultLoader />
  ) : token ? (
    <>
      {message && <Alert kind={message.id}>{message.msg}</Alert>}
      <div className="form">
        <TextField
          name="customer_name"
          label="Name"
          onChange={handleChange}
          value={formData.customer_name}
          fullWidth
          variant="filled"
          size="small"
          style={{ margin: "1.25rem 0" }}
          required
          disabled={uploading}
        />
      </div>
      {size(formData.customer_name) > 0 ? (
        <div className="form">
          <Card>
            <MultiUploader
              entity="paragraph"
              bundle="job_division_file"
              field="field_file"
              initialValues={{}}
              maxFiles={30}
              maxFileSize={5}
              onUploadSuccess={async (meta, fid, setComplete) => {
                setComplete((complete) => complete + 1);
              }}
              onUploadButtonClick={async (uppy) => {
                setUploading(true);
                const recaptchaToken = await recaptchaRef.current.executeAsync();
                uppy.setMeta({...metaFields, recaptcha_token: recaptchaToken});
                recaptchaRef.current.reset();
              }}
              onUploadStart={() => {
              }}
              onUploadFail={ async (setComplete, response, file) => {
                setUploading(false);
                if(response.status === 422 && response.body.message === "Invalid token" && !file?.retried){
                  await handleVerifyHash(hash, true);
                  return true;
                }
                setMessage({
                  id: "negative",
                  msg: "Error occured when uploading File(s)",
                });
                // setComplete((complete) => complete + 1);
                return false;
              }}
              onRemove={() => {}}
              onComplete={() => {
                setMessage({
                  id: "positive",
                  msg: "File(s) uploaded successfully",
                });
                setFormData({
                  ...formData,
                  customer_name: "",
                  recaptcha_token: "",
                });
                setUploading(false);
              }}
              form={(props) => (
                <Form
                  categoryNameOverride={"Photos"}
                  references={references}
                  roleType=""
                  {...props}
                />
              )}
              onClose={() => {}}
              onChangeBasedOnType={() => {}}
              headers={({ file }) => ({
                "Content-Disposition": `file; filename="${file.name}"`,
              })}
              endpoint={`${config.api_url}/rest/elocal/upload?_format=hal_json`}
              formData
              metaFields={metaFields}
              metaIncludeFormData={true}
              // autoProceed={false}
              // disableProgress
              fileTypes={[
                ".jpg",
                ".jpeg",
                ".gif",
                ".png",
                ".heic",
              ]}
              onSelect={handleSelect}
              selected={selected}
              setAllSelectable={setAllSelectable}
              fileToolbar={
                (props) => (
                  <FileToolbar
                    inUpload={true}
                    references={references}
                    selected={selected}
                    onClear={() => {setSelected([]); setAllSelected(false);}}
                    allSelected={allSelected}
                    onSelectAll={handleSelectAll}
                    type="multiselect"
                    subType={"photos"}
                    {...props}
                  />
                )
              }
            />

          <ReCAPTCHA
            ref={recaptchaRef}
            size="invisible"
            sitekey={config.recaptcha_invisible_site_key}
          />
          </Card>
        </div>
      ) : (
        <Empty message="Enter your name above to begin uploading photos.">
          <GiArchiveResearch />
        </Empty>
      )}
    </>
  ) : (
    <div className="form">
      <Empty message="This link is no longer valid or the job is no longer accepting uploads">
        <GiArchiveResearch />
      </Empty>
    </div>
  );
};

Upload.propTypes = {};

export default Upload;
