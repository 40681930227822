import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Typography,
  ListItem,
  ListItemIcon,
  ListItemText,
  Grid,
} from "@mui/material";
import { GoDiffAdded } from "react-icons/go";
import { RiEdit2Fill } from "react-icons/ri";

import { TextFormField } from "common/Fields";
import { DialogForm } from "common/Dialog";

import {
  patchInsurance,
  postInsurance,
  getInsuranceDataSelector,
} from "../insuranceSlice";
import { getDivisionDataSelector } from "features/Job/jobSlice";

const Create = ({ children }) => {
  const dispatch = useDispatch();
  const division = useSelector(getDivisionDataSelector);
  const insurance = useSelector(getInsuranceDataSelector);
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleSubmit = (data) => {
    const params = {
      field_jdi_adjuster_email: [{ value: data.field_jdi_adjuster_email }],
      field_jdi_adjuster_fax: [{ value: data.field_jdi_adjuster_fax }],
      field_jdi_adjuster_name: [{ value: data.field_jdi_adjuster_name }],
      field_jdi_adjuster_phone: [{ value: data.field_jdi_adjuster_phone }],
      field_jdi_agent_email: [{ value: data.field_jdi_agent_email }],
      field_jdi_agent_fax: [{ value: data.field_jdi_agent_fax }],
      field_jdi_agent_name: [{ value: data.field_jdi_agent_name }],
      field_jdi_agent_phone: [{ value: data.field_jdi_agent_phone }],
      field_jdi_carrier: [{ value: data.field_jdi_carrier }],
      field_jdi_claim_number: [{ value: data.field_jdi_claim_number }],
      field_jdi_policy_number: [{ value: data.field_jdi_policy_number }],
      field_jdi_deductible: [{ value: data.field_jdi_deductible }],
    };

    if (insurance.nid) {
      dispatch(patchInsurance({ id: insurance.nid, params }));
    } else {
      dispatch(
        postInsurance({ id: division.nid, title: division.title, params })
      );
    }

    onClose();
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      {children ? (
        React.cloneElement(children, {
          onClick: handleOpen,
        })
      ) : (
        <ListItem button onClick={handleOpen} style={{ paddingLeft: "2rem" }}>
          <ListItemIcon>
            {insurance.nid ? <RiEdit2Fill /> : <GoDiffAdded />}
          </ListItemIcon>
          <ListItemText primary="Insurance" />
        </ListItem>
      )}
      <DialogForm
        open={open}
        onClose={onClose}
        maxWidth="sm"
        title={insurance.nid ? "Edit Insurance" : "Add Insurance"}
        initialValues={{
          field_jdi_adjuster_email: insurance.field_jdi_adjuster_email,
          field_jdi_adjuster_fax: insurance.field_jdi_adjuster_fax,
          field_jdi_adjuster_name: insurance.field_jdi_adjuster_name,
          field_jdi_adjuster_phone: insurance.field_jdi_adjuster_phone,
          field_jdi_agent_email: insurance.field_jdi_agent_email,
          field_jdi_agent_fax: insurance.field_jdi_agent_fax,
          field_jdi_agent_name: insurance.field_jdi_agent_name,
          field_jdi_agent_phone: insurance.field_jdi_agent_phone,
          field_jdi_carrier: insurance.field_jdi_carrier,
          field_jdi_claim_number: insurance.field_jdi_claim_number,
          field_jdi_policy_number: insurance.field_jdi_policy_number,
          field_jdi_deductible: insurance.field_jdi_deductible,
        }}
        disablePadding
        onSubmit={handleSubmit}
        disableOverlay
      >
        {({ values, errors, isSubmitting, validateField, setFieldValue }) => (
          <div style={{ padding: "1.25rem" }}>
            <TextFormField
              name="field_jdi_carrier"
              label="Carrier"
              fullWidth
              variant="filled"
              size="small"
            />
            <Typography
              variant="subtitle1"
              color="textSecondary"
              style={{ margin: "1rem 0" }}
            >
              Adjuster
            </Typography>
            <Grid container spacing={3}>
              <Grid item xxs={6} md={3}>
                <TextFormField
                  name="field_jdi_adjuster_name"
                  label="Name"
                  fullWidth
                  variant="filled"
                  size="small"
                />
              </Grid>
              <Grid item xxs={6} md={3}>
                <TextFormField
                  name="field_jdi_adjuster_phone"
                  label="Phone"
                  fullWidth
                  isPhone
                  variant="filled"
                  size="small"
                />
              </Grid>
              <Grid item xxs={6} md={3}>
                <TextFormField
                  name="field_jdi_adjuster_fax"
                  label="Fax"
                  fullWidth
                  isPhone
                  variant="filled"
                  size="small"
                />
              </Grid>
              <Grid item xxs={6} md={3}>
                <TextFormField
                  name="field_jdi_adjuster_email"
                  label="Email"
                  fullWidth
                  variant="filled"
                  size="small"
                />
              </Grid>
            </Grid>
            <Typography
              variant="subtitle1"
              color="textSecondary"
              style={{ margin: "1rem 0" }}
            >
              Agent
            </Typography>
            <Grid container spacing={3}>
              <Grid item xxs={6} md={3}>
                <TextFormField
                  name="field_jdi_agent_name"
                  label="Name"
                  fullWidth
                  variant="filled"
                  size="small"
                />
              </Grid>
              <Grid item xxs={6} md={3}>
                <TextFormField
                  name="field_jdi_agent_phone"
                  label="Phone"
                  fullWidth
                  isPhone
                  variant="filled"
                  size="small"
                />
              </Grid>
              <Grid item xxs={6} md={3}>
                <TextFormField
                  name="field_jdi_agent_fax"
                  label="Fax"
                  fullWidth
                  isPhone
                  variant="filled"
                  size="small"
                />
              </Grid>
              <Grid item xxs={6} md={3}>
                <TextFormField
                  name="field_jdi_agent_email"
                  label="Email"
                  fullWidth
                  variant="filled"
                  size="small"
                />
              </Grid>
              <Grid item xxs={12} sm={4}>
                <TextFormField
                  name="field_jdi_claim_number"
                  label="Claim #"
                  fullWidth
                  variant="filled"
                  size="small"
                />
              </Grid>
              <Grid item xxs={12} sm={4}>
                <TextFormField
                  name="field_jdi_policy_number"
                  label="Policy #"
                  fullWidth
                  variant="filled"
                  size="small"
                />
              </Grid>
              <Grid item xxs={12} sm={4}>
                <TextFormField
                  name="field_jdi_deductible"
                  label="Deductible"
                  fullWidth
                  variant="filled"
                  size="small"
                />
              </Grid>
            </Grid>
          </div>
        )}
      </DialogForm>
    </>
  );
};

Create.propTypes = {};

export default Create;
