import React, { useRef, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { IconButton, Tooltip } from "@mui/material";
import ButtonLoader from "common/ButtonLoader";
import Button from "common/Button";
import { RiImageEditFill } from "react-icons/ri";
import { ReactComponent as IconImageHistory } from "assets/images/icons/icon-image-history.svg";
import CloseIcon from "@mui/icons-material/Close";
import { useImageLoad } from "hooks";
import { DefaultLoader } from "common/Loader";
import { exportToCanvas, getShadowSettings } from "./utils";
import { ImageEditorComponent } from '@syncfusion/ej2-react-image-editor';
import { filesSelectors, patchFileEnhancement, patchFileEnhancementReset } from "features/Files/filesSlice";
import ConfirmationDialog from "components/ConfirmationDialog";
import { ImageZoom } from "components/Lightroom";
import { generateTempUUID } from "utility";

const ImageInteractionEditor = ({ fid, fileNode, routeUrl, openEditOnLoad, maximizeRefreshParam, ...rest }) => {
  let imgObj = useRef(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const file = useSelector((state) => filesSelectors.selectById(state, fid));
  const token = useSelector((state) => state.auth.token);
  let url = file.uri.replace("private://", "");
  const [loading, image, width, height] = useImageLoad(`/system/files/${url}`, token);
  let originalUrl = file?.original_uri ? file.original_uri.replace("private://", "") : null;
  const [originalLoading, originalImage, originalWidth, originalHeight] = useImageLoad(originalUrl ? `/system/files/${originalUrl}` : null, token); //eslint-disable-line
  const [useEditor, setUseEditor] = useState(openEditOnLoad);
  const [submitting, setSubmitting] = useState(false);
  const [confirmReset, setConfirmReset] = useState(false);
  const [originalView, setOriginalView] = useState(false);
  const [maximizeRefresh, setMaximizeRefresh] = useState('');


// Refresh the Editor on maximize
  useEffect(() => {
    if(imgObj?.current){
      const canvasWrapper = document.querySelector('#' + imgObj.current.id + '_canvasWrapper');
      canvasWrapper.style.width = null;
      canvasWrapper.style.height = null; // Force the editor to readjust the dimensions
      imgObj.current.update();
    }
  }, [maximizeRefreshParam, maximizeRefresh]);

  function imageEditorCreated() {
    if(image){
      imgObj.current.open(image);
    }
  }

  function beforeSave(args) {
    args.cancel = true;
  }

  const addShadow = (args) => {
  // Shadow Settings
    const { color, blur, offsetX, offsetY } = getShadowSettings();
    imgObj.current.lowerContext.shadowColor = color;
    imgObj.current.lowerContext.shadowBlur = blur;
    imgObj.current.lowerContext.shadowOffsetX = offsetX;
    imgObj.current.lowerContext.shadowOffsetY = offsetY;
    imgObj.current.upperContext.shadowColor = color;
    imgObj.current.upperContext.shadowBlur = blur;
    imgObj.current.upperContext.shadowOffsetX = offsetX;
    imgObj.current.upperContext.shadowOffsetY = offsetY;
  };

  const handleReset = async () => {
    setSubmitting(true);

    const resultAction = await dispatch(
      patchFileEnhancementReset({
        nid: fileNode.id,
      })
    );
    const payload = unwrapResult(resultAction);
    setSubmitting(false);
    setConfirmReset(false);
    setUseEditor(false);
    history.push(`${routeUrl}/preview/${payload.field_file.fid}`); // Load the new file preview
  }

  function handleConfirmClose() {
    setConfirmReset(false);
  }

  const handleSubmit = async () => {
    setSubmitting(true);
    imgObj.current.okBtn(false);
    var obj = { canvas: null, parent: imgObj.current };
    exportToCanvas(obj);
    const imgData = obj['canvas'].toDataURL(file.filemime);
    const resultAction = await dispatch(
      patchFileEnhancement({
        nid: fileNode.id,
        params: {data: imgData},
      })
    );
    const payload = unwrapResult(resultAction);
    setSubmitting(false);
    setUseEditor(false);
    history.push(`${routeUrl}/preview/${payload.field_file.fid}`); // Load the new file preview
  }

  let toolbar = ['ZoomIn', 'ZoomOut', 'Transform', 'Crop', 'Annotate', 'Reset'];

  return (
    !loading ? (
      !useEditor ? (
        <>
        <div className="image-view-wrapper">
          <ImageZoom
            ImageZoom={!originalView ? file.filename : file.original_filename}
            image={!originalView ? image : originalImage}
            imageWidth={!originalView ? width : originalWidth}
            imageHeight={!originalView ? height : originalHeight}
            refresh={String(maximizeRefreshParam + maximizeRefresh)}
          />

          <div className="editor-buttons">
            <Tooltip title="Adjust and Annotate the Image">
              <span> {/* Avoids and error where Tooltip cannot directly wrap a disabled Mui button */}
                <IconButton
                  className="icon-circle-button"
                  onClick={() => setUseEditor(true)}
                  disabled={originalView}
                >
                  <RiImageEditFill className="w-[150%] h-[150%] -m-[3px]" />
                </IconButton>
              </span>
            </Tooltip>
            {Boolean(fileNode?.is_enhanced) &&
              <Tooltip title={!originalView ? "View the original image" : "Stop viewing the original image"}>
                <IconButton
                  className="icon-circle-button original-image"
                  onClick={() => {
                    setOriginalView(!originalView);
                    setTimeout(() => setMaximizeRefresh(generateTempUUID()), 100);
                  }}
                >
                  {!originalView ? (
                    <IconImageHistory />
                  ) : (
                    <CloseIcon />
                  )}
                </IconButton>
              </Tooltip>
            }
          </div>
        </div>
        </>
      ) : (
        <div className="control-pane-wrapper flex flex-col h-full">
          <div className="control-pane flex-[1_1_auto] relative">
            <ImageEditorComponent
              id="image-editor"
              ref={imgObj}
              created={imageEditorCreated}
              beforeSave={beforeSave}
              shapeChanging={addShadow}
              selectionChanging={addShadow}
              toolbarItemClicked={addShadow}
              disabled={submitting}
              toolbar={toolbar}
            />
            {submitting &&
              <div className="absolute w-full h-full top-0 left-0 bg-white/50">
                <DefaultLoader className="absolute top-0 right-0 bottom-0 left-0 m-auto" />
              </div>}
          </div>
          <div className="flex justify-end p-2">
            <div className="flex-[1_0_auto]">
              {fileNode.is_enhanced ? (
                <>
                <Button
                  onClick={() => setConfirmReset(true)}
                  size="small"
                  variant="outlined"
                  disabled={submitting}
                >
                  Reset Photo
                </Button>
                <ConfirmationDialog
                  open={confirmReset}
                  onClose={handleConfirmClose}
                  onSubmit={handleReset}
                  isSubmitting={submitting}
                  title="Are you sure?"
                  body={
                    <>
                    <p>Resetting the photo will remove any shapes, texts, or image adjustments.</p>
                    <p>Are you sure that you want to reset this photo?</p>
                    </>
                  }
                  submitButton="Reset Photo"
                />
                </>
              ) : (
                <div />
              )}
            </div>
            <Button
              onClick={() => setUseEditor(false)}
              size="small"
              variant="outlined"
              disabled={submitting}
              className="mr-4"
            >
              Cancel
            </Button>
            <ButtonLoader
              size="small"
              color="primary"
              variant="contained"
              disableElevation
              isSubmitting={submitting}
              onClick={handleSubmit}
            >
              Save Changes
            </ButtonLoader>
          </div>
        </div>
      )
  ) : (
    <DefaultLoader className="absolute inset-0 m-auto" />
  ));
}

ImageInteractionEditor.propTypes = {};

export default ImageInteractionEditor;
