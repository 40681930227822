import React, { useCallback, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  ClickAwayListener,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { AiOutlineUserAdd } from "react-icons/ai";

import { AutocompleteFormField } from "common/Fields";
import { DialogForm } from "common/Dialog";

import {
  memberUsersSelector,
  getMemberUsersLoadingSelector,
  fetchMemberUsers,
} from "features/Users/usersSlice";
import { getDivisionDataSelector, patchJobDivision } from "../jobSlice";
import { getMemberDataSelector } from "features/Member/memberSlice";
import { toArray } from "lodash";
import { FieldArray } from "formik";
import Button from "common/Button";
import { IconButton, Typography } from "@mui/material";
import styled from "styled-components";
import { MdDeleteSweep } from "react-icons/md";
import { AutocompleteFormFieldStatic } from "common/Fields";
import { size } from "lodash";
import { unwrapResult } from "@reduxjs/toolkit";

const types = {
  estimator: "Estimator",
  pm: "Project Manager",
  supervisor: "Supervisor",
  coordinator: "Coordinator",
  accounting: "Accounting",
  marketing: "Marketing",
  foreman: "Foreman",
  lead_technician: "Lead Technician",
  mrp: "Managed Repair Personnel",
};

const Styles = styled.div`
  padding: 1.25rem;

  .table {
    &__wrapper {
      overflow-x: auto;
    }
  }

  table {
    width: 100%;
    border-spacing: 0;
    color: var(--color-black);

    th,
    td {
      padding: 0.5rem;
      border-bottom: 1px solid var(--color-gray-medium);
      border-left: 1px solid var(--color-gray-medium);

      &:first-child {
        border-left: none;
      }
    }

    th {
      text-align: left;
    }

    td {
      min-height: 56px;
    }
  }

  .table-actions {
    padding: 1rem 0;
    display: flex;
    justify-content: space-between;

    button {
      margin-right: 1rem;
    }
  }
`;


const Personnel = ({ children, personnelType, assignedOnly }) => {
  const dispatch = useDispatch();
  const division = useSelector(getDivisionDataSelector);
  const [focusItem, setFocusItem] = useState(null);
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleSubmit = async (data) => {
    try {
      const users = [];
      data.field_jd_personnel.forEach((user) => {
        if (user?.user?.uid && user?.type?.value) {
          users.push({ uid: user.user.uid, type: user.type.value });
        }
      });
      if(personnelType && division.field_jd_personnel){
        division.field_jd_personnel.forEach((user) => {
          if(personnelType.value !== user.type){
            users.push({
              uid: user.uid,
              type: user.type,
            });
          }
        });
      }

      const resultAction = await dispatch(
        patchJobDivision({
          id: division.nid,
          params: { field_jd_personnel: users },
        })
      );

      unwrapResult(resultAction);

      onClose();
    } catch (err) {
      console.error(err);
    }
  };

  const onClose = () => {
    setOpen(false);
  };

  const handleFocusItem = (id) => {
    setFocusItem(id);
  };

  const handleBlurItem = () => {
    setFocusItem(null);
  };

  const handleRemoveItem = (e, i, values, arrayHelpers) => {
    e.stopPropagation();

    if (size(values.field_jd_personnel) > 1 && i !== "last") {
      arrayHelpers.remove(i);
    } else if (size(values.field_jd_personnel) === 1 && i !== "last") {
      arrayHelpers.remove(i);
      arrayHelpers.push({
        user: null,
        type: null,
      });
    }
  };

  const handleResetItems = (setFieldValue) => {
    setFieldValue("field_jd_personnel", [
      {
        user: null,
        type: null,
      },
    ]);
  };

  const handleAddItems = (setFieldValue, values) => {
    setFieldValue("field_jd_personnel", [
      ...values.field_jd_personnel,
      {
        user: null,
        type: null,
      },
    ]);
  };

  const initialValues = useMemo(() => {
    const personnel = division.field_jd_personnel;
    if (personnel) {
      const users = [];
      personnel.forEach((user) => {
        if(!personnelType || personnelType.value === user.type){
          users.push({
            user: {
              name: user.full_name,
              uid: user.uid,
            },
            type: {
              value: user.type,
              name: types[user.type],
            },
          });
        }
      });

      return users;
    } else {
      return [{ user: null, type: null }];
    }
  }, [division, personnelType]);

  const title = (personnelType && personnelType?.name ? personnelType.name : "Personnel");

  return (
    <>
      {children ? (
        React.cloneElement(children, {
          onClick: handleOpen,
        })
      ) : (
        <ListItem button onClick={handleOpen} style={{ paddingLeft: "2rem" }}>
          <ListItemIcon>
            <AiOutlineUserAdd />
          </ListItemIcon>
          <ListItemText primary={title} />
        </ListItem>
      )}
      <DialogForm
        open={open}
        onClose={onClose}
        maxWidth="sm"
        title={title}
        initialValues={{
          field_jd_personnel: initialValues,
        }}
        disablePadding
        onSubmit={handleSubmit}
        // fillScreen
      >
        {({ values, errors, isSubmitting, validateField, setFieldValue }) => (
          <Styles>
            <Table
              name="field_jd_personnel"
              personnelType={personnelType}
              values={values}
              setFieldValue={setFieldValue}
              focusItem={focusItem}
              handleFocusItem={handleFocusItem}
              handleRemoveItem={handleRemoveItem}
              handleBlurItem={handleBlurItem}
              isSubmitting={isSubmitting}
              handleAddItems={handleAddItems}
              handleResetItems={handleResetItems}
            />
          </Styles>
        )}
      </DialogForm>
    </>
  );
};

const Table = ({
  name,
  personnelType,
  values,
  setFieldValue,
  focusItem,
  handleFocusItem,
  handleRemoveItem,
  handleBlurItem,
  isSubmitting,
  handleAddItems,
  handleResetItems,
}) => {

  const title = (personnelType && personnelType?.name ? personnelType.name : "Personnel");

  return (
    <div style={{ paddingTop: "2rem" }}>
      <FieldArray
        name={name}
        render={(arrayHelpers) => (
          <>
            <ClickAwayListener onClickAway={handleBlurItem}>
              <div className="table__wrapper">
                <table>
                  <thead>
                    <tr>
                      <th>User</th>
                      {!personnelType && (<th>Role</th>)}
                      <th style={{ width: 50 }}></th>
                    </tr>
                  </thead>
                  <tbody>
                    {values.field_jd_personnel.map((detail, i) => (
                        <LineItem
                          name={name}
                          personnelType={personnelType}
                          setFieldValue={setFieldValue}
                          lineNumber={i + 1}
                          editing={focusItem === i}
                          onClick={() => handleFocusItem(i)}
                          onRemove={(e) =>
                            handleRemoveItem(e, i, values, arrayHelpers)
                          }
                          item={detail}
                          key={`${i}-default`}
                          isSubmitting={isSubmitting}
                          index={i}
                        />
                    ))}
                    {values.field_jd_personnel.length === 0 && (
                      <LineItem
                        name={name}
                        personnelType={personnelType}
                        editing={false}
                        lineNumber={size(values[name]) + 1}
                        onRemove={(e) =>
                          handleRemoveItem(e, "last", values, arrayHelpers)
                        }
                        onClick={() => {
                          const newLastIndex = size(values.field_jd_personnel);
                          handleFocusItem(newLastIndex);
                          arrayHelpers.push({
                            user: null,
                            type: "",
                          });
                        }}
                        key="last-default"
                        isSubmitting={isSubmitting}
                        index={"last"}
                      />
                    )}
                  </tbody>
                </table>
              </div>
            </ClickAwayListener>
            <div className="table-actions">
              <div className="table-actions--left">
                <Button
                  variant="outlined"
                  size="small"
                  color="tertiary"
                  disableElevation
                  onClick={() => handleAddItems(setFieldValue, values)}
                >
                  {`Add ${title}`}
                </Button>
                <Button
                  variant="outlined"
                  size="small"
                  color="tertiary"
                  disableElevation
                  onClick={() => handleResetItems(setFieldValue)}
                >
                  Clear All
                </Button>
              </div>
            </div>
          </>
        )}
      />
    </div>
  );
};


const LineStyles = styled.tr`
  transition: background-color 0.25s;
  cursor: pointer;
  background-color: white;

  &:nth-child(even) {
    background-color: var(--color-gray-light);
  }

  &:hover {
    background-color: var(--color-gray-background);
  }

  .cell {
    min-height: 46px;
    display: flex;
    justify-content: center;
    min-width: 100px;
    flex-direction: column;

    &--center {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

const LineItem = ({ personnelType, editing, onClick, onBlur, index, item, ...rest }) => {
  return (
    <LineStyles onClick={onClick} theme={{ editing }} index={index}>
      {editing ? (
        <EditMode personnelType={personnelType} item={item} index={index} {...rest} />
      ) : (
        <ViewMode personnelType={personnelType} item={item} index={index} {...rest} />
      )}
    </LineStyles>
  );
};

const EditMode = ({ personnelType, index, lineNumber, setFieldValue, isSubmitting, onRemove }) => {
  const dispatch = useDispatch();
  const users = useSelector(memberUsersSelector.selectAll);
  const member = useSelector(getMemberDataSelector);
  const loading = useSelector(getMemberUsersLoadingSelector);

  const getUsers = useCallback(
    (query) => {
      return dispatch(
        fetchMemberUsers({
          id: member.nid,
          onlyAssigned: 0,
          params: {
            keywords: query,
          },
        })
      );
    },
    [member, dispatch]
  );

  return (
    <>
      <td>
        <AutocompleteFormField
          name={`field_jd_personnel.${index}.user`}
          label="User"
          margin="normal"
          disabled={isSubmitting}
          options={toArray(users).map((user) => ({
            ...user,
            name: user.name,
            uid: user.uid,
          }))}
          loading={loading}
          fetchData={getUsers}
          variant="outlined"
          size="small"
          onChange={(e, value) => {
            setFieldValue(`field_jd_personnel.${index}.user`, value);
            if(personnelType && personnelType?.value){
              setFieldValue(`field_jd_personnel.${index}.type`, personnelType);
            }
          }}
        />
      </td>
      {!personnelType && (
      <td>
        <AutocompleteFormFieldStatic
          fullWidth
          name={`field_jd_personnel.${index}.type`}
          label="Type"
          margin="normal"
          size="small"
          variant="outlined"
          disabled={isSubmitting}
          options={[
            { value: "auditor", name: "Auditor" },
            { value: "estimator", name: "Estimator" },
            { value: "pm", name: "Project Manager" },
            { value: "supervisor", name: "Supervisor" },
            { value: "coordinator", name: "Coordinator" },
            { value: "accounting", name: "Accounting" },
            { value: "marketing", name: "Marketing" },
            { value: "foreman", name: "Foreman" },
            {
              value: "lead_technician",
              name: "Lead Technician",
            },
            {
              value: "mrp",
              name: "Managed Repair Personnel",
            },
          ]}
        />
      </td>
      )}
      <td>
        <div className="cell--center">
          <IconButton onClick={onRemove}>
            <MdDeleteSweep />
          </IconButton>
        </div>
      </td>
    </>
  );
};

const ViewMode = ({ personnelType, index, lineNumber, item, onRemove, isSubmitting }) => {
  return (
    <>
      <td>
        <div className="cell">
          <Typography>{item?.user?.name}</Typography>
        </div>
      </td>
      {!personnelType && (
      <td>
        <div className="cell">
          <Typography>{item?.type?.name}</Typography>
        </div>
      </td>
      )}
      <td>
        <div className="cell cell--center">
          <IconButton onClick={onRemove}>
            <MdDeleteSweep />
          </IconButton>
        </div>
      </td>
    </>
  );
};

Personnel.propTypes = {};

export default Personnel;
