import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  DialogContent,
  DialogActions,
  Typography,
  Grid,
  CircularProgress,
} from "@mui/material";
import Uppy from "@uppy/core";
import XHRUpload from "@uppy/xhr-upload";
import DragDrop from "@uppy/drop-target";
import ThumbnailGenerator from "@uppy/thumbnail-generator";
import { size, toArray } from "lodash";
import styled from "styled-components";

import { StatusBar, FileInput } from "@uppy/react";
import AwsS3 from '@uppy/aws-s3';

import Button from "common/Button";
import { formatFile, getPercentage } from "../../utility";
import config from "../../config";
import FileItem from "./FileItem";
import ButtonLoader from "common/ButtonLoader";
import Camera from "./Camera";

import "@uppy/core/dist/style.css";
import "@uppy/drag-drop/dist/style.css";
import "@uppy/dashboard/dist/style.css";
import "./uploader-styles.scss";
import Toolbar from "common/Toolbar";
import { fetchAWSToken, moveAWSTempFile } from "./uploaderSlice.js";

const uploadIllustration = `${process.env.PUBLIC_URL}/images/icons/icon-upload--illustration.svg`;

const Empty = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const StyledButton = styled.div`
  button {
    overflow: visible;
    cursor: pointer;
    margin: 0;
    display: inline-flex;
    outline: 0;
    position: relative;
    align-items: center;
    user-select: none;
    vertical-align: middle;
    justify-content: center;
    text-decoration: none;
    background-color: var(--color-blue);
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
    color: white;
    min-width: 64px;
    box-sizing: border-box;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-family: Source Sans Pro, "Helvetica Neue", Arial, sans-serif;
    font-weight: bold;
    line-height: 1.75;
    border-radius: 4px;
    text-transform: uppercase;
    border: 1px solid var(--color-blue);
    box-shadow: none;
    padding: 3px 9px;
    font-size: 0.8125rem;
    margin-bottom: 1rem;
  }
`;

const MultiUploader = ({
  entity,
  bundle,
  field,
  initialValues,
  onUploadSuccess,
  onUploadButtonClick,
  onUploadStart,
  onUploadFail,
  onRemove,
  onClose,
  onComplete,
  form: Form,
  onChangeBasedOnType,
  headers,
  endpoint,
  formData,
  metaFields,
  metaIncludeFormData,
  fileTypes,
  disableProgress,
  hideStatus,
  autoProceed,
  onSelect,
  selected,
  setAllSelectable,
  fileToolbar: FileToolbar,
  maxFiles,
  maxFileSize,
  useAWS,
}) => {
  const dispatch = useDispatch();
  const [filesState, _setFiles] = useState({});
  const [formDataState, _setFormData] = useState({});
  const [complete, setComplete] = useState(0);
  const [cancelled, setCancelled] = useState(0);
  const [total, setTotal] = useState(0);
  const [isSubmitting, setSubmitting] = useState(false);
  const [uploading, setUploading] = useState(false);
  const auth = useSelector((state) => state.auth.token);
  const csrf = useSelector((state) => state.auth.csrf);
  const filesRef = useRef(filesState);
  const formDataRef = useRef(formDataState);
  const [resetDelay, setResetDelay] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    // console.log({ complete, total });
    if (complete + cancelled === total){
      setUploading(false);
      if(total > 0) {
        setSubmitting(false);
        onComplete();
      }
    }
  }, [complete, cancelled, total, onComplete]);

  const setFiles = (data) => {
    filesRef.current = data;
    _setFiles(data);

    if(setAllSelectable){
      setAllSelectable(toArray(data).map((file) => file.id));
    }
  };

  const setFormData = (data) => {
    formDataRef.current = data;
    _setFormData(data);
  };

  const [uppy] = useState(() => {
    const types = (fileTypes ? fileTypes :
      [
        "image/*",
        ".jpg",
        ".jpeg",
        ".gif",
        ".png",
        ".svg",
        ".pdf",
        ".doc",
        ".docx",
        ".ppt",
        ".pptx",
        ".xls",
        ".xlsx",
        ".csv",
        ".zip",
        ".tmproject",
        ".esx",
        ".bdata",
        ".xml",
        ".webloc",
        ".xlsb",
        ".heic",
      ]
    );

    const _uppy =  new Uppy({
      meta: metaFields,
      autoProceed: autoProceed,
      restrictions: {
        allowedFileTypes: types,
        maxNumberOfFiles: maxFiles ? maxFiles : 0,
        maxFileSize: maxFileSize ? maxFileSize : (useAWS ? 100000000 : 10000000), // 100MB max for AWS, otherwise 10MB
      },
      onBeforeFileAdded: (currentFile, files) => {
      //  Strip any Microsoft hyphens that may have snuck in.
        const newName = currentFile.name.replace(/–/g, '_');
        const modifiedFile = {
          ...currentFile,
          name: newName,
        }
        return modifiedFile
      },
    })
      .use(DragDrop, {
        target: document.body,
      })
      .use(ThumbnailGenerator, {
        thumbnailWidth: 200,
        waitForThumbnailsBeforeUpload: true,
      })
      .on("file-added", (file) => {
        const files = filesRef.current;
        const formData = formDataRef.current;
        setFiles({ ...files, [file.id]: file });
        setFormData({ ...formData, [file.id]: initialValues });
      })
      .on("thumbnail:generated", (file, preview) => {
        const files = filesRef.current;
        setFiles({ ...files, [file.id]: file });
      })
      .on("upload", (uploadID, files ) => {
        const _files = filesRef.current;
        const currentFiles = { ..._files };
        files.forEach((file) => currentFiles[file.id].progress.uploadStarted = true);

        onUploadStart();
        setFiles({ ...currentFiles });
        setUploading(true);
        setErrorMessage(null);
      })
      .on("upload-progress", (file, progress) => {
        const files = filesRef.current;
        const percentage = getPercentage(
          progress.bytesUploaded,
          progress.bytesTotal
        );
        files[file.id].progress.bytesTotal = progress.bytesTotal;
        files[file.id].progress.bytesUploaded = progress.bytesUploaded;
        files[file.id].progress.percentage = percentage;
        setFiles({ ...files });
      })
      .on("upload-success", async (file, response) => {
        const files = filesRef.current;
        const formData = formDataRef.current;
        file.progress.uploadComplete = true;

        let fileData = {};
        if(useAWS){
          const params = {
            file_location: decodeURIComponent(response.uploadURL),
            entity_type: entity,
            bundle: bundle,
            field_name: field,
          };
          const fileResponse = await dispatch(moveAWSTempFile({params}));
          fileData = formatFile({ data: fileResponse.payload });

          // const fileResponse = await axios.post(
          //   `${config.api_url}/aws-move-temp`,
          //   {
          //     file_location: decodeURIComponent(response.uploadURL),
          //     entity_type: entity,
          //     bundle: bundle,
          //     field_name: field,
          //   },
          //   {
          //     withCredentials: true,
          //     headers: {
          //       Authorization: `Bearer ${auth}`,
          //     },
          //   }
          // );
          // fileData = formatFile({ data: fileResponse.data });
        }
        else{
          fileData = formatFile({ data: response.body });
        }
        file.fileData = fileData;
        setFiles({ ...files, [file.id]: file });

        onUploadSuccess(formData[file.id], fileData.fid, setComplete);
      })
      .on("upload-error", async (file, error, response) => {
        const files = filesRef.current;
        file.error = error;

        const retry = await onUploadFail(setComplete, response, file);
        if(retry){
          setResetDelay(file.id);
          file.retried = true;
        }
        else{
          setSubmitting(false);
        }

        setFiles({ ...files, [file.id]: file });
        setErrorMessage(error.message);
      })
      .on("upload-retry", (fileID) => {
        const files = filesRef.current;
        files[fileID].progress.uploadStarted = true;
        files[fileID].error = null;
        files[fileID].progress.bytesUploaded = 0;
        files[fileID].progress.percentage = 0;

        setFiles({ ...files });
        setErrorMessage(null);
      })
      .on('file-removed', (file) => {
        setCancelled(cancelled + 1);
        setErrorMessage(null);
      })
      .on("complete", (result) => {
        setSubmitting(true);
        setTotal(result.successful.length + result.failed.length);
        setUploading(false);
      })
      .on('restriction-failed', (file, error) => {
        setErrorMessage(error.message);
      });

    const uppyPluginOptions = {
      limit: 1,
      timeout: 0,
      formData: formData ? formData : false,
      headers: (file) =>
        headers
          ? headers({ file })
          : {
              "X-CSRF-Token": csrf,
              "Content-Type": "application/octet-stream",
              "Content-Disposition": `file; filename="${encodeURIComponent(file.name)}"`,
              Authorization: `Bearer ${auth}`,
            },
      endpoint: endpoint
        ? endpoint
        : `${config.api_url}/file/upload/${entity}/${bundle}/${field}`,
    };

    _uppy.log('debug');

    if(!useAWS){
      _uppy.use(XHRUpload,uppyPluginOptions )
    }
    else{
      _uppy.use(AwsS3, {
        // ...uppyPluginOptions,
        // This is an example not using Companion:
        getTemporarySecurityCredentials: true,
        async getUploadParameters (file) {
          const params = {
            filename: file.name,
            contentType: file.type,
          }
          const response = await dispatch(fetchAWSToken({params}));
          return response.payload;
        },
        shouldUseMultipart: (file) => file.size > 100 * 2 ** 20,
      });
    }

    return _uppy;
  });

  const updateMeta = useCallback(() => {
    const meta = {...metaFields};
    uppy.setMeta(meta);
    if(metaIncludeFormData && formDataState){
      Object.entries(formDataState).forEach((entry) => {
        const [id, value] = entry;
        uppy.setFileMeta(id, value);
      })
    }
  }, [metaFields, formDataState, metaIncludeFormData, uppy]);

  const handleRetryUpload = useCallback((id) => {
    uppy.retryUpload(id);
  }, [uppy]);

  useEffect(() => {
    if (metaFields) {
      updateMeta();
      if(resetDelay){
        const id = resetDelay;
        setResetDelay(null);
        handleRetryUpload(id);
      }
    }
  }, [metaFields, updateMeta, resetDelay, handleRetryUpload]);

  const handleUpload = async () => {
    if(onUploadButtonClick){
      await onUploadButtonClick(uppy);
    }
    uppy.upload();
  };


  const handleRemove = (id) => {
    const files = filesRef.current;
    const currentFields = { ...formDataState };
    delete files[id];
    delete currentFields[id];

    onRemove(id);
    setFormData({ ...currentFields });
    setFiles({ ...files });
    uppy.removeFile(id);

    if(selected && selected.indexOf(id) > -1){
      onSelect(id);
      setAllSelectable(toArray(files).map((file) => file.id));
    }

    setUploading(false);
  };

  const handleClose = () => {
    setFiles({});
    setFormData({});
    setSubmitting(false);
    onClose();
  };

  const filesStateArray = toArray(filesState);

  return (
    <>
      <DialogContent
        id="drop-area"
        style={{ position: "relative", padding: "24px" }}
      >
        {isSubmitting && !disableProgress && (
          <div
            style={{
              position: "absolute",
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
              background: "rgba(255,255,255,0.5)",
              zIndex: 4,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress />
          </div>
        )}
        <Toolbar
          disablePadding
          left={<Camera uppy={uppy} />}
          right={
            size(filesStateArray) > 0 && (
              <StyledButton>
                <FileInput uppy={uppy} />
              </StyledButton>
            )
          }
          subToolbar={(FileToolbar && size(filesStateArray) > 0) ? (
            <FileToolbar
              formData={formDataState}
              setFormData={setFormData}
            />
          ) : null}
        />
        <Grid container spacing={3}>
          {maxFiles &&
            <Grid item xxs={12}>
              <Typography className="text-center">
                Limit {maxFiles} file(s) at a time
              </Typography>
            </Grid>
          }

          {size(filesStateArray) > 0 ? (
            filesStateArray.map((file) => (
              <Grid item xxs={12} sm={6} md={4}>
                <FileItem
                  file={file}
                  acquirers={[]}
                  retryUpload={() => handleRetryUpload(file.id)}
                  onRemove={() => handleRemove(file.id)}
                  meta={formDataState[file.id]}
                  onChangeBasedOnType={onChangeBasedOnType}
                  formData={formDataState}
                  setFormData={setFormData}
                  onSelect={onSelect}
                  selected={selected}
                  form={
                    Form ? (
                      <Form
                        formData={formDataState}
                        setFormData={setFormData}
                        id={file.id}
                      />
                    ) : null
                  }
                />
              </Grid>
            ))
          ) : (
            <Grid item xxs={12}>
              <Empty>
                <img
                  src={uploadIllustration}
                  alt="Empty"
                  style={{ marginLeft: "1rem" }}
                />
                <Typography style={{ margin: "0.5rem 0 1rem 0" }}>
                  Drag and Drop files to upload
                </Typography>
                <StyledButton>
                  <FileInput uppy={uppy} />
                </StyledButton>
              </Empty>
            </Grid>
          )}
        </Grid>
        {hideStatus && (
          <StatusBar
            uppy={uppy}
            hideUploadButton
            hideRetryButton
            hideAfterFinish
          />
        )}
      </DialogContent>
      <DialogActions
        style={{
          background: "var(--color-gray-lightest)",
          borderTop: "1px solid var(--color-gray-medium)",
        }}
      >
        {errorMessage !== null &&
          <div className="uppy-error-message-wrapper"><div className="message">{errorMessage}</div></div>
        }
        {!autoProceed && (
          <>
            {onClose && (
              <Button
                size="small"
                style={{ marginRight: "1rem" }}
                onClick={handleClose}
                variant="outlined"
              >
                Cancel
              </Button>
            )}
            <ButtonLoader
              isSubmitting={isSubmitting}
              disabled={uploading}
              onClick={handleUpload}
              size="small"
              variant="contained"
              disableElevation
              color="primary"
            >
              Upload
            </ButtonLoader>
          </>
        )}
      </DialogActions>
    </>
  );
};

MultiUploader.propTypes = {};

export default MultiUploader;
