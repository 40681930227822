//TODO: add overrides for expense types

import React, { useEffect, useState, useCallback, useRef, } from "react";
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { first, toArray, size } from "lodash";
import {
  Grid,
  InputAdornment,
  useMediaQuery,
  MenuItem,
  Tooltip,
  ListItem,
  ListItemText,
  ListItemIcon,
  FormGroup,
  Typography,
  IconButton,
} from "@mui/material";
import { Tune, BorderColor, Close } from "@mui/icons-material";
import moment from "moment-timezone";
import { AiOutlineFileAdd } from "react-icons/ai";
import Alert from "features/Alert";

import { DialogForm } from "common/Dialog";
import { AutocompleteFormField } from "common/Fields";
import { SelectFormField } from "common/Fields";
import { CheckboxFormField } from "common/Fields";
import { DateFormField } from "common/Fields";
import { TimeFormField } from "common/Fields";
import { TextFormField } from "common/Fields";
import ButtonDropdown from "common/ButtonDropdown";
import Totals from "../components/Totals";
import Overrides from "./Overrides";
import {
  setTotalHours,
  calcOverallTotal,
  calcTotalAmt,
  calcOverallHours,
  calcFlatHours,
  calcFlatTotal,
  parseFormServiceTimes,
  calcMarkup,
  calcSmallTools,
} from "../utils";

import {
  memberUsersSelector,
  getMemberUsersLoadingSelector,
  fetchMemberUsersTemp,
} from "features/Users/usersSlice";
import {
  postService,
  patchBreak,
  deleteBreak,
  patchService,
  postBreak,
  getDailysheetsLoadingSelector,
} from "features/DailySheets/dailysheetsSlice";
import {
  fetchDailysheet,
  clearDailysheet,
  fetchUserServiceHours,
  getDailySheetDataSelector,
  getDailySheetLoadingSelector,
  getUserHoursDataSelector,
} from "features/DailySheet/dailySheetSlice";
import { getMemberDataSelector } from "features/Job/jobSlice";
import { getDivisionDataSelector } from "features/Job/jobSlice";
import {
  fetchWorkAreas,
  getAreasLoadingSelector,
  workAreasSelectors,
} from "features/WorkAreas/workAreasSlice";
import {
  laborActivitiesSelectors,
  laborTypesSelectors,
} from "features/PriceList/priceListSlice";
import TempUsersQuickAdd from "features/Users/forms/TempUsersQuickAdd";
import TMTermsLaborTermDetails from "components/TMTerms/components/TMTermsLaborTermDetails";
import { checkTMTermLaborItem } from "components/TMTerms/utils";
import { fetchDivisionSnapshot } from "features/Job/jobSlice";
import { checkTMMarkup } from "components/TMTerms/utils";
import TMTermsDailySheetMarkupComponent from "components/TMTerms/forms/components/TMTermsDailySheetMarkupComponent";
import Guard from "common/Guard";
import { isDivisionStatusPast } from "utility";

const ServiceAddEdit = ({ nid, row, handleRefresh, icon, initUser, ...props }) => {
  const dispatch = useDispatch();
  const formRef = useRef();
  const user = useSelector((state) => state.auth.user.data);
  const userProcessed = useSelector((state) => state.auth.user.data._processed);
  const member = useSelector(getMemberDataSelector);
  const users = useSelector(memberUsersSelector.selectAll);
  const loading = useSelector(getMemberUsersLoadingSelector);
  const activities = useSelector(laborActivitiesSelectors.selectAll);
  const types = useSelector(laborTypesSelectors.selectAll);
  const division = useSelector(getDivisionDataSelector);
  const divisionProcessed = division?._processed ? division._processed : {};
  const userHours = useSelector(getUserHoursDataSelector);
  const areas = useSelector(workAreasSelectors.selectAll);
  const loadingAreas = useSelector(getAreasLoadingSelector);
  const loadingSheet = useSelector(getDailySheetLoadingSelector);
  const loadingSheets = useSelector(getDailysheetsLoadingSelector);
  const sheet = useSelector(getDailySheetDataSelector);
  const divisionStatuses = useSelector((state) => state.taxonomies.jobDivisionStatus.entities)

  const userTimeZone = user?.timezone;
  let timeZone = moment.tz.guess();
  if(!timeZone) {
    timeZone = userTimeZone;
  }

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const memberHours = {...member.field_hours};
  if(userProcessed?.week_start){
    memberHours.week_start = userProcessed?.week_start;
  }
  const markup = checkTMMarkup(division?.field_tm_terms, "labor");

  const [open, setOpen] = useState(false);
  const [date, setDate] = useState(moment());

//  Set some default rates from the T&M Terms
  let tmRateDefaults = {
    has_perdiem: false,
    perdiem: 0,
    has_ppe: false,
    ppe: 0,
    has_stc: false,
    small_tools: 3,
    has_lodging: false,
    lodging: 0,
  };
  const setTMValueDefaults = () => {
    if(division?.field_tm_terms?.labor?.per_diem?.value){
      tmRateDefaults.has_perdiem = true;
      tmRateDefaults.perdiem = division.field_tm_terms.labor.per_diem.value;
    }
    if(division?.field_tm_terms?.labor?.ppe?.value){
      tmRateDefaults.has_ppe = true;
      tmRateDefaults.ppe = division.field_tm_terms.labor.ppe.value;
    }
    if(division?.field_tm_terms?.labor?.small_tools_charge?.percent){
      tmRateDefaults.has_stc = true;
      tmRateDefaults.small_tools = division.field_tm_terms.labor.small_tools_charge.percent * 100;
    }
    if(division?.field_tm_terms?.labor?.lodging?.value){
      tmRateDefaults.has_lodging = true;
      tmRateDefaults.lodging = division.field_tm_terms.labor.lodging.value;
    }
  };
  setTMValueDefaults();

  useEffect(() => {
    let promise;
    if (open){
      dispatch(clearDailysheet());
      if(nid) {
        promise = dispatch(fetchDailysheet(nid));
      }
    }

    return () => {
      if (promise) {
        promise.abort();
      }
    };
  }, [dispatch, nid, open]);

  useEffect(() => {
    let promise;
    if (open) {
      let params = null;
      if(nid){
        if(sheet){
          const user = sheet.user
            ? {
                name: sheet.user[0].name,
                entity_id: sheet.user[0].uid,
                type: "user",
              }
            : sheet.tempUser
            ? {
                name: sheet.tempUser[0].name,
                entity_id: sheet.tempUser[0].nid,
                type: "temporary_user",
              }
            : null;
          if (user) {
            params = {
              date: moment(sheet.date).format("YYYY-MM-DD"),
              id: user?.entity_id,
              type: user?.type,
            }
          }
        }
      }
      else if(division.nid){
        params = {
          date: moment(date).format("YYYY-MM-DD"),
        }
        if(initUser){
          params.id = initUser.entity_id;
          params.type = initUser.type;
        }
      }
      if(params){
        promise = dispatch(
          fetchUserServiceHours(params)
        );
      }
    }

    return () => {
      if (promise) {
        promise.abort();
      }
    };
  }, [dispatch, nid, open, division, date, sheet]); //eslint-disable-line

  const getUsers = useCallback(
    (query) => {
      return dispatch(
        fetchMemberUsersTemp({
          id: member?.nid,
          params: {
            keywords: query,
          },
        })
      );
    },
    [member, dispatch]
  );

  const getAreas = useCallback(
    (query) => {
      return dispatch(
        fetchWorkAreas({
          id: division?.nid,
          params: {
            keywords: query,
          },
        })
      );
    },
    [division, dispatch]
  );

  const handleCreateBreak = async (data, nid) => {
    const times = parseFormServiceTimes(data);
    if (times.breakStart && times.breakEnd) {
      const params = {
        field_ds_breaks_start_time: [
          {
            value: times.breakStart.format(`YYYY-MM-DD[T]HH:mm:00Z`),
          },
        ],
        field_ds_breaks_end_time: [
          {
            value: times.breakEnd.format(`YYYY-MM-DD[T]HH:mm:00Z`),
          },
        ],
      };

      const resultAction = await dispatch(postBreak({ id: nid, params }));
      return resultAction;
    } else {
      return null;
    }
  };

  const handleUpdateBreak = async (data, pid) => {
    const times = parseFormServiceTimes(data);
    if (times.breakStart && times.breakEnd) {
      const params = {
        field_ds_breaks_start_time: [
          {
            value: times.breakStart.format(`YYYY-MM-DD[T]HH:mm:00Z`),
          },
        ],
        field_ds_breaks_end_time: [
          {
            value: times.breakEnd.format(`YYYY-MM-DD[T]HH:mm:00Z`),
          },
        ],
      };

      const resultAction = await dispatch(patchBreak({ id: pid, params }));
      return resultAction;
    } else if(pid) {
      const resultAction = await dispatch(deleteBreak({id: pid}));
      return resultAction;
    } else {
      return null;
    }
  };

  const handleSubmit = async (
    data,
    setSubmitting,
    setMessage,
    successCallback,
    errorCallback
  ) => {
    const times = parseFormServiceTimes(data);
    let params = {
      ...(typeof data.field_visibility != 'undefined') && { field_visibility: [{ value: data.field_visibility ? 'phx_client' : null }]},
    };
    if(!nid){
      params.title = [{ value: `Daily Sheet for ${division.title}`}];
    }
    const smallTools = calcSmallTools(data);
    const markup = calcMarkup(data);

    params = {
      ...params,
      field_ds_service_status: [{ value: 'complete' }],
      field_ds_service_activity_text: [{ value: data.activity }],
      field_ds_labor_type_text: [{ value: data.type }],
      field_ds_date: [{ value: moment(data.date).format("YYYY-MM-DD") }],
      field_ds_entry_type: [{ value: data.entry }],
      field_ds_private_description: [{ value: data.description }],
      field_ds_show_descript_invoice: [{ value: data.show }],
      field_ds_exclude_invoice: [{ value: data.exclude }],
      field_ds_onsite: [{ value: data.onsite }],
      field_ds_has_perdiem: [{ value: data["has_perdiem"] }],
      field_ds_perdiem_total: [{ value: data.perdiem }],
      field_ds_job_division: [{ target_id: division.nid }],
      field_ds_taxable: [{ value: data.field_ds_taxable }],
      field_ds_service_ppe_total: [{ value: data.ppe }],
      field_ds_service_stc_total: [{ value: smallTools }],
      field_ds_service_stc_prct: [{ value: data["has_stc"] && data["small_tools"] ? data["small_tools"] / 100 : 0 }],
      field_ds_service_lodging_total: [{ value: data.lodging }],
      field_ds_markup: markup ? [{ value: markup }] : null,
      field_ds_percent_markup: null,
    };

    if (data.entry === "hourly") {
      params = {
        ...params,
        field_ds_start_time: [
          {
            value: times.start.format(`YYYY-MM-DD[T]HH:mm:00Z`),
          },
        ],
        field_ds_end_time: [
          {
            value: times.end.format(`YYYY-MM-DD[T]HH:mm:00Z`),
          },
        ],
        field_ds_after_hours_ovr_rate: [{ value: data["rate-after-override"] }],
        field_ds_override_billable_hrs: [
          { value: data["billable-service-override"] },
        ],
        field_ds_override_rate: [{ value: data["rate-service-override"] }],
        field_ds_travel_ovr_rate: [{ value: data["rate-travel-override"] }],
        field_ds_overtime_bill_ovr_hrs: [
          { value: data["billable-overtime-override"] },
        ],
        field_ds_after_hours_bll_ovr_hrs: [
          { value: data["billable-after-override"] },
        ],
        field_ds_overtime_ovr_rate: [{ value: data["rate-overtime-override"] }],
        field_ds_travel_billable_ovr_hrs: [
          { value: data["billable-travel-override"] },
        ],
        field_ds_service_hrs: [{ value: data["service-billable"] }],
        field_ds_billable_hrs: [{ value: data["service-billable"] }],
        field_ds_overtime_hrs: [{ value: data["overtime-billable"] }],
        field_ds_overtime_billable_hrs: [{ value: data["overtime-billable"] }],
        field_ds_travel_hrs: [{ value: data["travel-billable"] }],
        field_ds_travel_billable_hrs: [{ value: data["travel-billable"] }],
        field_ds_after_hours_hrs: [{ value: data["after-billable"] }],
        field_ds_after_hours_bill_hrs: [{ value: data["after-billable"] }],
        field_ds_rate: [{ value: data["service-rate"] }],
        field_ds_overtime_rate: [{ value: data["overtime-rate"] }],
        field_ds_travel_rate: [{ value: data["travel-rate"] }],
        field_ds_after_hours_rate: [{ value: data["after-rate"] }],
        field_ds_total: [{ value: calcOverallTotal(data) }],
        field_ds_overtime_total: [
          {
            value: calcTotalAmt(
              data["overtime-rate"],
              data["overtime-billable"]
            ),
          },
        ],
        field_ds_after_hours_total: [
          {
            value: calcTotalAmt(data["after-rate"], data["after-billable"]),
          },
        ],
        field_ds_travel_total: [
          {
            value: calcTotalAmt(data["travel-rate"], data["travel-billable"]),
          },
        ],
        field_ds_total_hours: [{ value: calcOverallHours(data) }],
        field_ds_service_total: [
          {
            value: calcTotalAmt(data["service-rate"], data["service-billable"]),
          },
        ],
        field_ds_break_hrs: [{ value: data.breakHours }],
      };
    } else {
      params = {
        ...params,
        field_ds_service_total: [{ value: data["service-total"] }],
        field_ds_overtime_total: [
          {
            value: data["overtime-total"],
          },
        ],
        field_ds_after_hours_total: [
          {
            value: data["after-total"],
          },
        ],
        field_ds_travel_total: [
          {
            value: data["travel-total"],
          },
        ],
        field_ds_service_hrs: [{ value: data["service-hours"] }],
        field_ds_overtime_hrs: [
          {
            value: data["overtime-hours"],
          },
        ],
        field_ds_after_hours_hrs: [
          {
            value: data["after-hours"],
          },
        ],
        field_ds_travel_hrs: [
          {
            value: data["travel-hours"],
          },
        ],
        field_ds_total_hours: [{ value: calcFlatHours(data) }],
        field_ds_total: [{ value: calcFlatTotal(data) }],
      };
    }

    if (data.workarea) {
      params.field_ds_work_area_allocation = [{ target_id: data.workarea.nid }];
    } else {
      params.field_ds_work_area_allocation = [];
    }

    if (data.user.type === "user") {
      params.field_ds_user = [{ target_id: data.user.entity_id ? data.user.entity_id : data.user.uid }];
      params.field_ds_user_temp = [];
    } else if (data.user.type === "temporary_user") {
      params.field_ds_user_temp = [{ target_id: data.user.entity_id ? data.user.entity_id : data.user.uid }];
      params.field_ds_user = [];
    }

    if(data.markup_type === 'percent' && Number(data.field_ds_percent_markup) > 0){
      params.field_ds_percent_markup = [{ value: Number(data.field_ds_percent_markup) }];
    }

    try {
      let resultAction = null;
      if(!nid){
        resultAction = await dispatch(
          postService({ id: division.nid, params })
        );
      }
      else{
        resultAction = await dispatch(
          patchService({ id: sheet.nid, params, user: data.user })
        );
      }

      unwrapResult(resultAction);

      const newNid = resultAction.payload.nid;
      try {
        if (sheet.breakParagraphId) {
          const breakResultAction = await handleUpdateBreak(
            data,
            sheet.breakParagraphId
          );
          unwrapResult(breakResultAction);
        } else {
          const breakResultAction = await handleCreateBreak(data, (nid ? sheet.nid : newNid));
          unwrapResult(breakResultAction);
        }
      } catch (error) {
        console.error(error.message);
      }
      if(props?.fromSummaryCard){
        dispatch(fetchDivisionSnapshot(division.nid));
      }
      handleRefresh();
      handleOnClose();
    } catch (err) {
      console.error("Failed to save: ", err);
    }
  };

  const handleOnClose = () => {
    setOpen(false);
  };

  const onTimeChange = async (
    field,
    diffStart,
    diffEnd,
    value,
    setFieldValue,
    values,
    breakStart,
    breakEnd,
    nid
  ) => {
    setFieldValue(field, value);

    setTotalHours(
      values.date,
      diffStart,
      diffEnd,
      breakStart,
      breakEnd,
      memberHours,
      setFieldValue,
      values,
      userHours,
      division.nid
    );
  };

  const onDateChange = async (field, value, setFieldValue, values) => {
    setDate(value);
    setFieldValue(field, value);
    try {
      const resultAction = await dispatch(
        fetchUserServiceHours({
          date: moment(value).format("YYYY-MM-DD"),
          id: (values?.user?.uid ? values.user.uid : values?.user?.entity_id),
          type: values?.user?.type,
        })
      );

      unwrapResult(resultAction);
      const hours = resultAction.payload;
      setTotalHours(
        value,
        values.start,
        values.end,
        values.breakstart,
        values.breakend,
        memberHours,
        setFieldValue,
        values,
        hours,
        division.nid
      );
    } catch (err) {
      console.error(err);
    }
  };

  const onUserChange = async (field, value, setFieldValue, values) => {
    setFieldValue(field, value);

    try {
      const resultAction = await dispatch(
        fetchUserServiceHours({
          date: moment(values?.date).format("YYYY-MM-DD"),
          id: (value?.entity_id ? value.entity_id : values?.user?.uid),
          type: (value?.type ? value.type : values?.user?.type),
        })
      );

      unwrapResult(resultAction);
      const hours = resultAction.payload;
      setTotalHours(
        values.date,
        values.start,
        values.end,
        values.breakstart,
        values.breakend,
        memberHours,
        setFieldValue,
        values,
        hours,
        division.nid
      );
    } catch (err) {
      console.error(err);
    }

    if(value?.service_types){
      laborTypeUpdate(first(value?.service_types), setFieldValue, values);
    }
    else if(value?.user?.service_types){
      laborTypeUpdate(first(value?.user?.service_types), setFieldValue, values);
    }
  };

  const getLaborTypeRates = (value, values, resetDefaults) => {
    const rates = {};
    if (types) {
      for (let key in types) {
        if (types[key].name === value) {
          let serviceRate = types[key].s;
          if (!values["rate-service-override"]) {
            rates["service-rate"] = serviceRate;
          }
          else{
            serviceRate = values["service-rate"];
          }
          if(division?.field_tm_terms?.labor?.overtime){
            if(checkTMTermLaborItem(division?.field_tm_terms?.labor?.overtime, key)){
              values["rate-overtime-override"] = true;
              rates["overtime-rate"] = serviceRate * Number(division.field_tm_terms.labor.overtime.multiplier);
            }
            else{
              values["rate-overtime-override"] = false;
            }
            rates["rate-overtime-override"] = values["rate-overtime-override"];
          }
          if (!values["rate-overtime-override"]) {
            rates["overtime-rate"] = types[key].o;
          }
          if(division?.field_tm_terms?.labor?.after_hours){
            if(checkTMTermLaborItem(division?.field_tm_terms?.labor?.after_hours, key)){
              values["rate-after-override"] = true;
              rates["after-rate"] = serviceRate * Number(division.field_tm_terms.labor.after_hours.multiplier);
            }
            else{
              values["rate-after-override"] = false;
            }
            rates["rate-after-override"] = values["rate-after-override"];
          }
          if (!values["rate-after-override"]) {
            rates["after-rate"] = types[key].e;
          }
          if (!values["rate-travel-override"]) {
            rates["travel-rate"] = types[key].t;
          }

          if(resetDefaults){
            if(checkTMTermLaborItem(division?.field_tm_terms?.labor?.travel, key)){
              if (!Number(values["travel-hours"])) {
                rates["travel-hours"] = division.field_tm_terms.labor.travel.value;
                rates["travel-billable"] = rates["travel-hours"];
              }
            }
            if(checkTMTermLaborItem(division?.field_tm_terms?.labor?.per_diem, key)){
              rates["has_perdiem"] = true;
              rates["perdiem"] = division.field_tm_terms.labor.per_diem.value;
            }
            else{
              rates["has_perdiem"] = false;
              rates["perdiem"] = 0;
            }
            if(checkTMTermLaborItem(division?.field_tm_terms?.labor?.ppe, key)){
              rates["has_ppe"] = true;
              rates["ppe"] = division.field_tm_terms.labor.ppe.value;
            }
            else{
              rates["has_ppe"] = false;
              rates["ppe"] = 0;
            }
            if(checkTMTermLaborItem(division?.field_tm_terms?.labor?.small_tools_charge, key)){
              rates["has_stc"] = true;
              rates["small_tools"] = division.field_tm_terms.labor.small_tools_charge.percent * 100;
            }
            else{
              rates["has_stc"] = false;
              rates["small_tools"] = 0;
            }
            if(checkTMTermLaborItem(division?.field_tm_terms?.labor?.lodging, key)){
              rates["has_lodging"] = true;
              rates["lodging"] = division.field_tm_terms.labor.lodging.value;
            }
            else{
              rates["has_lodging"] = false;
              rates["lodging"] = 0;
            }

            rates["field_ds_markup"] = '';
            rates["field_ds_percent_markup"] = '';
            rates["markup_type"] = '';
            const _markup = checkTMMarkup(division?.field_tm_terms, "labor", key);
            if(_markup){
              rates["field_ds_markup"] = _markup?.value ? _markup.value : '';
              rates["field_ds_percent_markup"] = _markup?.percent ? _markup.percent : '';
              rates["markup_type"] = _markup?.value ? 'flat' : 'percent';
            }
          }
          break;
        }
      }
    }
    return rates;
  }
  const laborTypeUpdate = (value, setFieldValue, values) => {
    setFieldValue("type", value);
    const rates = getLaborTypeRates(value, values, true);
    Object.entries(rates).forEach((entry, i) => {
      const [index, value] = entry;
      setFieldValue(index, value);
    });
    // if (types) {
    //   for (let key in types) {
    //     if (types[key].name === value) {
    //       let serviceRate = types[key].s;
    //       if (!values["rate-service-override"]) {
    //         setFieldValue("service-rate", serviceRate);
    //       }
    //       else{
    //         serviceRate = values["service-rate"];
    //       }
    //       if(division?.field_tm_terms?.labor?.overtime){
    //         if(checkTMTermLaborItem(division?.field_tm_terms?.labor?.overtime, key)){
    //           values["rate-overtime-override"] = true;
    //           setFieldValue("overtime-rate", serviceRate * Number(division.field_tm_terms.labor.overtime.multiplier));
    //         }
    //         else{
    //           values["rate-overtime-override"] = false;
    //         }
    //         setFieldValue("rate-overtime-override", values["rate-overtime-override"]);
    //       }
    //       if (!values["rate-overtime-override"]) {
    //         setFieldValue("overtime-rate", types[key].o);
    //       }
    //       if(division?.field_tm_terms?.labor?.after_hours){
    //         if(checkTMTermLaborItem(division?.field_tm_terms?.labor?.after_hours, key)){
    //           values["rate-after-override"] = true;
    //           setFieldValue("after-rate", serviceRate * Number(division.field_tm_terms.labor.after_hours.multiplier));
    //         }
    //         else{
    //           values["rate-after-override"] = false;
    //         }
    //         setFieldValue("rate-after-override", values["rate-after-override"]);
    //       }
    //       if (!values["rate-after-override"]) {
    //         setFieldValue("after-rate", types[key].e);
    //       }
    //       if (!values["rate-travel-override"]) {
    //         setFieldValue("travel-rate", types[key].t);
    //       }

    //       if(checkTMTermLaborItem(division?.field_tm_terms?.labor?.per_diem, key)){
    //         setFieldValue("has_perdiem", true);
    //         setFieldValue("perdiem", division.field_tm_terms.labor.per_diem.value);
    //       }
    //       else{
    //         setFieldValue("has_perdiem", false);
    //       }
    //       if(checkTMTermLaborItem(division?.field_tm_terms?.labor?.ppe, key)){
    //         setFieldValue("has_ppe", true);
    //         setFieldValue("ppe", division.field_tm_terms.labor.ppe.value);
    //       }
    //       else{
    //         setFieldValue("has_ppe", false);
    //       }
    //       if(checkTMTermLaborItem(division?.field_tm_terms?.labor?.small_tools_charge, key)){
    //         setFieldValue("has_stc", true);
    //         setFieldValue("small_tools", division.field_tm_terms.labor.small_tools_charge.percent * 100);
    //       }
    //       else{
    //         setFieldValue("has_stc", false);
    //       }
    //       if(checkTMTermLaborItem(division?.field_tm_terms?.labor?.lodging, key)){
    //         setFieldValue("has_lodging", true);
    //         setFieldValue("lodging", division.field_tm_terms.labor.lodging.value);
    //       }
    //       else{
    //         setFieldValue("has_lodging", false);
    //       }
    //     }
    //   }
    // }
  }

  let initialValues = {};
  let initialUser = {};
  if(!nid){
    let serviceType = (user?.field_user_settings?.service_types ? first(user?.field_user_settings?.service_types) : "")
    if(initUser){
      initialUser = {...initUser};
      serviceType = (initUser?.service_types && size(initUser?.service_types) ? first(initUser.service_types) : serviceType);
    }
    else{
      initialUser = {
        uid: user.uid,
        name: user.name,
        type: "user",
        service_types: (user?.field_user_settings?.service_types ? user?.field_user_settings?.service_types : []),
      };
    }

    initialValues = {
      user: initialUser,
      type: serviceType,
      activity: "",
      date: moment(),
      start: moment(),
      breakstart: null,
      breakend: null,
      end: null,
      entry: "hourly",
      description: "",
      onsite: true,
      exclude: false,
      billable: false,
      show: false,
      breaks: 0,
      breakHours: 0,
      "service-billable": 0,
      "billable-service-override": false,
      "travel-billable": 0,
      "billable-travel-override": false,
      "overtime-billable": 0,
      "billable-overtime-override": false,
      "after-billable": 0,
      "billable-after-override": false,
      "service-rate": 0,
      "rate-service-override": false,
      "travel-rate": 0,
      "rate-travel-override": false,
      "overtime-rate": 0,
      "rate-overtime-override": false,
      "after-rate": 0,
      "rate-after-override": false,
      "service-hours": null,
      "service-total": null,
      "travel-hours": null,
      "travel-total": null,
      "overtime-hours": null,
      "overtime-total": null,
      "after-hours": null,
      "after-total": null,
      workarea: null,
      military: false,
      field_ds_taxable: true,
      perdiem: tmRateDefaults.perdiem,
      "has_perdiem": tmRateDefaults.has_perdiem,
      ppe: tmRateDefaults.ppe,
      "has_ppe": tmRateDefaults.has_ppe,
      "has_stc": tmRateDefaults.has_stc,
      "small_tools": tmRateDefaults.small_tools,
      lodging: tmRateDefaults.lodging,
      "has_lodging": tmRateDefaults.has_lodging,
      field_ds_markup: markup && markup?.value ? markup.value : '',
      field_ds_percent_markup: markup && markup?.percent ? markup.percent : '',
      markup_type: markup ? (markup?.value ? 'flat' : 'percent') : '',
      ...(divisionProcessed?.ds_manage_entry_visibility) && { field_visibility: isDivisionStatusPast(division, divisionStatuses, 'review')}
    };
  }
  else{
    initialUser = sheet.user
                  ? {
                      name: sheet.user[0].first + ' ' + sheet.user[0].last,
                      entity_id: sheet.user[0].uid,
                      type: "user",
                    }
                  : sheet.tempUser
                  ? {
                      name: sheet.tempUser[0].name,
                      entity_id: sheet.tempUser[0].nid,
                      type: "temporary_user",
                    }
                  : null;
    initialValues = {
      user: initialUser,
      type: sheet.labor,
      activity: sheet.activity,
      date: moment(sheet.date),
      start: sheet.start ? moment(sheet.start).tz(timeZone) : null,
      end: sheet.end ? moment(sheet.end).tz(timeZone) : null,
      entry: sheet.entryType,
      description: sheet.description,
      onsite: sheet.onsite,
      exclude: sheet.excludeInvoice,
      billable: sheet.billable,
      show: sheet.showDescription,
      breaks: sheet.breaks,
      breakstart: sheet.breaks?.start
        ? moment(sheet.breaks.start).tz(timeZone)
        : null,
      breakend: sheet.breaks?.end
        ? moment(sheet.breaks.end).tz(timeZone)
        : null,
      breakHours: sheet.breakHrs,
      "service-billable": sheet.billableHrs,
      "billable-service-override": sheet.overrideBillHrs,
      "travel-billable": sheet.travelBillHrs,
      "billable-travel-override": sheet.travelBillOvrHrs,
      "overtime-billable": sheet.overtimeBillHrs,
      "billable-overtime-override": sheet.overtimeBillOvrHrs,
      "after-billable": sheet.afterHoursBillHrs,
      "billable-after-override": sheet.afterHoursBillOvrHrs,
      "service-rate": sheet.rate,
      "rate-service-override": sheet.overrideRate,
      "travel-rate": sheet.travelRate,
      "rate-travel-override": sheet.travelOvrRate,
      "overtime-rate": sheet.overtimeRate,
      "rate-overtime-override": sheet.overtimeOvrRate,
      "after-rate": sheet.afterHoursRate,
      "rate-after-override": sheet.afterHoursOvrRate,
      "service-hours": sheet.serviceHrs,
      "service-total": sheet.serviceTotal,
      "travel-hours": sheet.travelHrs,
      "travel-total": sheet.travelTotal,
      "overtime-hours": sheet.overtimeHrs,
      "overtime-total": sheet.overtimeTotal,
      "after-hours": sheet.afterHoursHrs,
      "after-total": sheet.afterHoursTotal,
      workarea: sheet.workarea?.nid
        ? {
            id: sheet.workarea.nid,
            title: sheet.workarea.title,
          }
        : null,
      "has_perdiem": sheet.hasPerdiem ? sheet.hasPerdiem : false,
      perdiem: sheet.perdiem ? sheet.perdiem : 0,
      field_ds_taxable: sheet.field_ds_taxable,
      ppe: sheet.ppe ? sheet.ppe : 0,
      "has_ppe": sheet.ppe > 0,
      "has_stc": sheet.smallToolsPercent > 0,
      "small_tools": sheet.smallToolsPercent ? Number(sheet.smallToolsPercent) * 100 : 3,
      lodging: sheet.lodging ? sheet.lodging : 0,
      "has_lodging": sheet.lodging > 0,
      field_ds_markup: sheet.field_ds_markup,
      field_ds_percent_markup: Number(sheet.field_ds_percent_markup) ? sheet.field_ds_percent_markup : '',
      markup_type: Number(sheet.field_ds_percent_markup) > 0 ? 'percent' : (Number(sheet.field_ds_markup) > 0 ? 'flat' : ''),
      ...(typeof sheet.field_visibility != 'undefined') && { field_visibility: sheet.field_visibility }
    };
  }
//  Update the rates based on the default service type
  if(initialValues.type && initialValues.type !== ''){
    initialValues = {
      ...initialValues,
      ...getLaborTypeRates(initialValues.type, initialValues, !nid),
    }
  }

//  Add the default user to the users options. (Avoids an annoying console warning)
  let userOptions = toArray(users).map((user) => ({
                      ...user,
                      name: user.name,
                      entity_id: user.uid,
                      uid: user.uid,
                      service_types: user.service_types_json,
                    }))
  if(initialUser && !userOptions.find((opt) => Number(opt.entity_id) === Number(initialUser.entity_id))){
    userOptions.push(initialUser)
  }

  // let userOptions = [];
  // let currentType = null;
  // toArray(users).forEach((user) => {
  //   const prefix = currentType !== user.type
  //     ? <div>{`(${user.type === 'user' ? 'Account User' : 'Non-Account User'}) `}</div>
  //     : '';
  //   currentType = user.type;
  //   userOptions.push({
  //     ...user,
  //     name: <div className="pl-2">{user.name}</div>,
  //     entity_id: user.uid,
  //     uid: user.uid,
  //     service_types: user.service_types_json,
  //   })
  // })

  return (
    <>
      {icon ? (
        React.cloneElement(icon, {
          onClick: () => setOpen(true),
        })
      ) : (
        !nid ? (
          <ListItem
            button
            style={{ paddingLeft: "3rem" }}
            onClick={() => setOpen(true)}
            className={props?.className}
          >
          <ListItemIcon>
            <AiOutlineFileAdd />
          </ListItemIcon>
          <ListItemText primary="Single Labor" />
        </ListItem>
        ) : (
          isMobile ? (
            <MenuItem onClick={() => setOpen(true)}>
              <ListItemIcon>
                <BorderColor />
              </ListItemIcon>
              <Typography variant="inherit">Edit</Typography>
            </MenuItem>
          ) : (
            <Tooltip title="Edit Labor Entry">
              <IconButton
                size="small"
                onClick={() => setOpen(true)}
                style={{ marginRight: "0.5rem" }}
              >
                <BorderColor />
              </IconButton>
            </Tooltip>
          )
        )
      )}
      <DialogForm
        open={open}
        onClose={handleOnClose}
        maxWidth="sm"
        title={!nid ? 'Add Labor' : 'Edit Labor'}
        loading={loadingSheet || loadingSheets}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        disablePadding
        formRef={formRef}
      >
        {({ values, errors, isSubmitting, validateField, setFieldValue }) => (
          <div style={{ padding: "0" }}>
            <div style={{ padding: "1.25rem" }}>
              <TempUsersQuickAdd className="mb-2" />
              <Overrides
                setFieldValue={setFieldValue}
                setTotalHours={setTotalHours}
                isSubmitting={isSubmitting}
                values={values}
                memberHours={memberHours}
                userHours={userHours}
                date={date}
                nid={division.nid}
                rates={types}
                className="mb-2"
              />
              <ButtonDropdown
                id="daily-sheets-actions-button"
                label="Options"
                color="tertiary"
                icon={Tune}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                className="mb-2"
              >
                <div style={{ padding: "1.25rem" }}>
                  <FormGroup style={{ width: "300px" }}>
                    <CheckboxFormField name="military" label="Military Time" />
                    <CheckboxFormField name="onsite" label="Onsite" />
                    <CheckboxFormField
                      name="exclude"
                      label="Exclude from invoice"
                    />
                    <CheckboxFormField name="billable" label="Billable" />
                    <CheckboxFormField
                      name="show"
                      label="Show private description on the invoice"
                    />
                    <CheckboxFormField
                      name="field_ds_taxable"
                      label="Taxable"
                    />
                    <Guard customOverrideName="canManageEntryVisibility">
                      <CheckboxFormField name="field_visibility" label="Do not share with provider" />
                    </Guard>
                  </FormGroup>
                  <AutocompleteFormField
                    name="workarea"
                    label="Allocate Work Area"
                    disabled={isSubmitting}
                    options={toArray(areas).map((area) => ({
                      name: area.title,
                      nid: area.nid,
                    }))}
                    loading={loadingAreas}
                    fetchData={getAreas}
                    variant="filled"
                    size="small"
                  />
                  <SelectFormField
                    fullWidth
                    name="entry"
                    label="Entry Type"
                    margin="normal"
                    variant="filled"
                    labelwidth={80}
                    required
                    disabled={isSubmitting}
                    options={[
                      {
                        value: "hourly",
                        label: "Hourly Work",
                      },
                      {
                        value: "flat",
                        label: "Flat Fee Work",
                      },
                    ]}
                  />
                </div>
              </ButtonDropdown>
            </div>
            {values && <Totals data={values} />}
            <TMTermsLaborTermDetails
              division={division}
              laborTypes={types}
            />
            <Alert margin="none" disableElevation disableRoundedCorners />
            <div style={{ padding: "1.25rem" }}>
              <Grid container spacing={3}>
                <Grid item xxs={12} xs={6} md={3}>
                  <AutocompleteFormField
                    name="user"
                    label="User"
                    disabled={isSubmitting}
                    options={userOptions}
                    groupBy={(option) => option.type === 'user' ? 'Account' : 'Offline'}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option.entity_id}>
                          {option.name}
                        </li>
                      );
                    }}
                    isOptionEqualToValue={(option, value) => Number(option.entity_id) === Number(value.entity_id)}
                    loading={loading}
                    fetchData={getUsers}
                    variant="filled"
                    size="small"
                    onChange={(e, value) => {
                      onUserChange("user", value, setFieldValue, values);
                    }}
                  />
                </Grid>
                <Grid item xxs={12} xs={6} md={3}>
                  <SelectFormField
                    fullWidth
                    name="activity"
                    label="Activity"
                    variant="filled"
                    size="small"
                    disabled={isSubmitting}
                    options={activities.map((activity) => ({
                      value: activity.name,
                      label: activity.name,
                    }))}
                  />
                </Grid>
                <Grid item xxs={12} xs={6} md={3}>
                  <SelectFormField
                    fullWidth
                    name="type"
                    label="Labor Type"
                    variant="filled"
                    size="small"
                    required
                    disabled={isSubmitting}
                    options={types.map((type) => ({
                      value: type.name,
                      label: type.name,
                    }))}
                    onChange={(e) => {
                      laborTypeUpdate(e.target.value, setFieldValue, values);
                    }}
                  />
                </Grid>
                <Grid item xxs={6} md={3}>
                  <DateFormField
                    fullWidth
                    disableToolbar
                    variant="inline"
                    format="MM/DD/YYYY"
                    id="date"
                    label="Date"
                    name="date"
                    inputVariant="filled"
                    size="small"
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                    disabled={isSubmitting}
                    onChange={(value) => {
                      onDateChange("date", value, setFieldValue, values);
                    }}
                  />
                </Grid>
                {values.entry === "hourly" ? (
                  <>
                    <Grid item xxs={6} md={3}>
                      <TimeFormField
                        fullWidth
                        variant="inline"
                        id="start"
                        label="Start Time"
                        name="start"
                        inputVariant="filled"
                        size="small"
                        required
                        ampm={!values.military}
                        disabled={isSubmitting}
                        onChange={(value) => {
                          onTimeChange(
                            "start",
                            value,
                            values.end,
                            value,
                            setFieldValue,
                            values,
                            values.breakstart,
                            values.breakend,
                            division.nid
                          );
                        }}
                        KeyboardButtonProps={{
                          "aria-label": "change start time",
                        }}
                      />
                    </Grid>
                    <Grid item xxs={6} md={3}>
                      <TimeFormField
                        fullWidth
                        variant="inline"
                        id="breakstart"
                        label="Break Start"
                        inputVariant="filled"
                        size="small"
                        name="breakstart"
                        ampm={!values.military}
                        disabled={isSubmitting}
                        onChange={(value) => {
                          onTimeChange(
                            "breakstart",
                            values.start,
                            values.end,
                            value,
                            setFieldValue,
                            values,
                            value,
                            values.breakend,
                            division.nid
                          );
                        }}
                        KeyboardButtonProps={{
                          "aria-label": "change break start time",
                        }}
                      />
                    </Grid>
                    <Grid item xxs={6} md={3}>
                      <TimeFormField
                        fullWidth
                        variant="inline"
                        id="breakend"
                        label="Break End"
                        inputVariant="filled"
                        size="small"
                        name="breakend"
                        ampm={!values.military}
                        disabled={isSubmitting}
                        onChange={(value) => {
                          onTimeChange(
                            "breakend",
                            values.start,
                            values.end,
                            value,
                            setFieldValue,
                            values,
                            values.breakstart,
                            value,
                            division.nid
                          );
                        }}
                        KeyboardButtonProps={{
                          "aria-label": "change break end time",
                        }}
                      />
                    </Grid>
                    <Grid item xxs={6} md={3}>
                      <TimeFormField
                        fullWidth
                        variant="inline"
                        id="end"
                        label="End Time"
                        inputVariant="filled"
                        size="small"
                        name="end"
                        required
                        ampm={!values.military}
                        disabled={isSubmitting}
                        onChange={(value) => {
                          onTimeChange(
                            "end",
                            values.start,
                            value,
                            value,
                            setFieldValue,
                            values,
                            values.breakstart,
                            values.breakend,
                            division.nid
                          );
                        }}
                        KeyboardButtonProps={{
                          "aria-label": "change end time",
                        }}
                      />
                    </Grid>
                    <Grid item xxs={6} md={3}>
                      <TextFormField
                        fullWidth
                        htmlFor="travel-hours"
                        name="travel-hours"
                        label="Travel (HRS)"
                        variant="filled"
                        size="small"
                        disabled={isSubmitting}
                        onChange={(e) => {
                          setFieldValue("travel-hours", e.target.value);
                          setFieldValue("travel-billable", e.target.value);
                        }}
                        labelwidth={70}
                      />
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid item xxs={6} md={3}>
                      <TextFormField
                        fullWidth
                        htmlFor="service-hours"
                        name="service-hours"
                        label="Labor (HRS)"
                        variant="filled"
                        size="small"
                        disabled={isSubmitting}
                        labelwidth={70}
                      />
                    </Grid>
                    <Grid item xxs={6} md={3}>
                      <TextFormField
                        fullWidth
                        htmlFor="service-total"
                        name="service-total"
                        label="Labor Total"
                        variant="filled"
                        size="small"
                        disabled={isSubmitting}
                        labelwidth={70}
                        startAdornment={
                          <InputAdornment position="start">$</InputAdornment>
                        }
                      />
                    </Grid>
                    <Grid item xxs={6} md={3}>
                      <TextFormField
                        fullWidth
                        htmlFor="travel-hours"
                        name="travel-hours"
                        label="Travel (HRS)"
                        variant="filled"
                        size="small"
                        disabled={isSubmitting}
                        labelwidth={70}
                      />
                    </Grid>
                    <Grid item xxs={6} md={3}>
                      <TextFormField
                        fullWidth
                        htmlFor="travel-total"
                        name="travel-total"
                        label="Travel Total"
                        variant="filled"
                        size="small"
                        disabled={isSubmitting}
                        labelwidth={70}
                        startAdornment={
                          <InputAdornment position="start">$</InputAdornment>
                        }
                      />
                    </Grid>
                    <Grid item xxs={6} md={3}>
                      <TextFormField
                        fullWidth
                        htmlFor="overtime-hours"
                        name="overtime-hours"
                        label="Overtime (HRS)"
                        variant="filled"
                        size="small"
                        disabled={isSubmitting}
                        labelwidth={70}
                      />
                    </Grid>
                    <Grid item xxs={6} md={3}>
                      <TextFormField
                        fullWidth
                        htmlFor="overtime-total"
                        name="overtime-total"
                        label="Overtime Total"
                        variant="filled"
                        size="small"
                        disabled={isSubmitting}
                        labelwidth={70}
                        startAdornment={
                          <InputAdornment position="start">$</InputAdornment>
                        }
                      />
                    </Grid>
                    <Grid item xxs={6} md={3}>
                      <TextFormField
                        fullWidth
                        htmlFor="after-hours"
                        name="after-hours"
                        label="After Hours (HRS)"
                        variant="filled"
                        size="small"
                        disabled={isSubmitting}
                        labelwidth={70}
                      />
                    </Grid>
                    <Grid item xxs={6} md={3}>
                      <TextFormField
                        fullWidth
                        htmlFor="after-total"
                        name="after-total"
                        label="After Hours Total"
                        variant="filled"
                        size="small"
                        disabled={isSubmitting}
                        labelwidth={70}
                        startAdornment={
                          <InputAdornment position="start">$</InputAdornment>
                        }
                      />
                    </Grid>
                  </>
                )}
                {values["has_perdiem"] ? (
                  <>
                    <Grid item xxs={6} md={3}>
                      <TextFormField
                        fullWidth
                        htmlFor="perdiem"
                        name="perdiem"
                        label="Per diem"
                        variant="filled"
                        size="small"
                        disabled={isSubmitting}
                        labelwidth={70}
                        startAdornment={
                          <InputAdornment position="start">$</InputAdornment>
                        }
                        endAdornment={
                          <InputAdornment position="end">
                            <Close
                              className="cursor-pointer"
                              onClick={() => {
                                setFieldValue("has_perdiem", false);
                                setFieldValue("perdiem", 0);
                              }}
                            />
                            {/* <CheckboxFormField
                              name="has_perdiem"
                              variant="filled"
                              size="small"
                              disabled={isSubmitting}
                              onChange={(e) => {
                                setFieldValue("has_perdiem", e.target.checked);

                                if (!e.target.checked) {
                                  setFieldValue("perdiem", 0);
                                }
                              }}
                            /> */}
                          </InputAdornment>
                        }
                      />
                    </Grid>
                  </>
                ) : (
                  <Grid item xxs={6} md={3}>
                    <CheckboxFormField
                      name="has_perdiem"
                      label="Per diem"
                      variant="filled"
                      size="small"
                      disabled={isSubmitting}
                      onChange={(e) => {
                        setFieldValue("has_perdiem", e.target.checked);

                        if (!e.target.checked) {
                          setFieldValue("perdiem", 0);
                        }
                      }}
                    />
                  </Grid>
                )}
                {values["has_ppe"] ? (
                  <Grid item xxs={6} md={3}>
                    <TextFormField
                      fullWidth
                      name="ppe"
                      label="PPE"
                      variant="filled"
                      size="small"
                      disabled={isSubmitting}
                      startAdornment={
                        <InputAdornment position="start">$</InputAdornment>
                      }
                      endAdornment={
                        <InputAdornment position="end">
                          <Close
                            className="cursor-pointer"
                            onClick={() => {
                              setFieldValue("has_ppe", false);
                              setFieldValue("ppe", 0);
                            }}
                          />
                          {/* <CheckboxFormField
                            name="has_ppe"
                            variant="filled"
                            size="small"
                            disabled={isSubmitting}
                            onChange={(e) => {
                              setFieldValue("has_ppe", e.target.checked);

                              if (!e.target.checked) {
                                setFieldValue("ppe", 0);
                              }
                            }}
                          /> */}
                        </InputAdornment>
                      }
                    />
                  </Grid>
                ) : (
                  <Grid item xxs={6} md={3}>
                    <CheckboxFormField
                      name="has_ppe"
                      label="PPE"
                      variant="filled"
                      size="small"
                      disabled={isSubmitting}
                      onChange={(e) => {
                        setFieldValue("has_ppe", e.target.checked);

                        if (!e.target.checked) {
                          setFieldValue("ppe", 0);
                        }
                      }}
                    />
                  </Grid>
                )}
                {values["has_stc"] ? (
                  <Grid item xxs={6} md={3}>
                    <TextFormField
                      fullWidth
                      name="small_tools"
                      label="Small Tools Charge"
                      variant="filled"
                      size="small"
                      disabled={isSubmitting}
                      startAdornment={
                        <InputAdornment position="start">
                          {/* <CheckboxFormField
                            name="has_stc"
                            variant="filled"
                            size="small"
                            disabled={isSubmitting}
                            onChange={(e) => {
                              setFieldValue("has_stc", e.target.checked);

                              if (!e.target.checked) {
                                setFieldValue("small_tools", 0);
                              }
                            }}
                          /> */}
                        </InputAdornment>
                      }
                      endAdornment={
                        <InputAdornment position="end">
                          %
                          <Close
                            className="cursor-pointer ml-2"
                            onClick={() => {
                              setFieldValue("has_stc", false);
                              setFieldValue("small_tools", 0);
                            }}
                          />
                        </InputAdornment>
                      }
                    />
                  </Grid>
                ) : (
                  <Grid item xxs={6} md={3}>
                    <CheckboxFormField
                      name="has_stc"
                      label="Small Tools Charge"
                      variant="filled"
                      size="small"
                      disabled={isSubmitting}
                      onChange={(e) => {
                        setFieldValue("has_stc", e.target.checked);

                        if (!e.target.checked) {
                          setFieldValue("small_tools", 0);
                        }
                      }}
                    />
                  </Grid>
                )}
                {values["has_lodging"] ? (
                  <Grid item xxs={6} md={3}>
                    <TextFormField
                      fullWidth
                      name="lodging"
                      label="Lodging"
                      variant="filled"
                      size="small"
                      disabled={isSubmitting}
                      startAdornment={
                        <InputAdornment position="start">$</InputAdornment>
                      }
                      endAdornment={
                        <InputAdornment position="end">
                          <Close
                            className="cursor-pointer"
                            onClick={() => {
                              setFieldValue("has_lodging", false);
                              setFieldValue("lodging", 0);
                            }}
                          />
                          {/* <CheckboxFormField
                            name="has_lodging"
                            variant="filled"
                            size="small"
                            disabled={isSubmitting}
                            onChange={(e) => {
                              setFieldValue("has_lodging", e.target.checked);

                              if (!e.target.checked) {
                                setFieldValue("lodging", 0);
                              }
                            }}
                          /> */}
                        </InputAdornment>
                      }
                    />
                  </Grid>
                ) : (
                  <Grid item xxs={6} md={3}>
                    <CheckboxFormField
                      name="has_lodging"
                      label="Lodging"
                      variant="filled"
                      size="small"
                      disabled={isSubmitting}
                      onChange={(e) => {
                        setFieldValue("has_lodging", e.target.checked);

                        if (!e.target.checked) {
                          setFieldValue("lodging", 0);
                        }
                      }}
                    />
                  </Grid>
                )}
                <Grid item xxs={12} md={9}>
                  <TMTermsDailySheetMarkupComponent
                    namePrefix=""
                    isSubmitting={isSubmitting}
                    values={values}
                    dsType="labor"
                  />
                </Grid>
                {/* <Grid item xxs={6} md={3}>
                  <CheckboxFormField
                    name="small_tools"
                    label="Small Tools"
                    variant="filled"
                    size="small"
                    disabled={isSubmitting}
                  />
                </Grid> */}
                <Grid item xxs={12} md={12}>
                  <TextFormField
                    fullWidth
                    htmlFor="description"
                    name="description"
                    label="Description"
                    variant="filled"
                    size="small"
                    disabled={isSubmitting}
                    multiline
                    labelwidth={70}
                  />
                </Grid>
              </Grid>
            </div>
          </div>
        )}
      </DialogForm>
    </>
  );
};

ServiceAddEdit.propTypes = {};

export default ServiceAddEdit;
