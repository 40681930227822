import React from "react";
import Layout from "@phx/common/Layout";
import styled from "styled-components";
import { Typography } from "@mui/material";

const icon = `${process.env.PUBLIC_URL}/images/helix_icon.svg`;

const Styles = styled.div`
  padding: 1.25rem;
  margin: 0 auto;
  max-width: 800px;
`;

const Terms = (props) => {
  return (
    <div
      style={{
        marginTop: "-62px",
        minHeight: "calc(var(--vh, 1vh) * 100)",
        background: "white",
      }}
    >
      <Layout
        name="Terms"
        title="Terms"
        meta="Terms of Service."
        icon={{ src: icon, alt: "Helixco Icon" }}
        loading={false}
      >
        <Styles>
          <Typography variant="h4" style={{ marginBottom: "1rem" }}>
            1. Introduction and Agreement
          </Typography>
          <Typography variant="body1" style={{ marginBottom: "1rem" }}>
            This End User License Agreement ("EULA") governs Your access to and use of the 
            software-as-a-service commonly known as Helix (the "SaaS Platform") made available
            to You by Helix Software ("Company," "We," or "Us").
          </Typography>
          <Typography variant="body1" style={{ marginBottom: "1rem" }}>
            This EULA is a legally binding agreement between You and Helix Software.
             By logging in you  agree, creating an account, or otherwise accessing 
             and using the SaaS Platform, You confirm that you have read, understood,
              and agree to be bound by all terms and conditions of this EULA.
          </Typography>
          <Typography variant="body1" style={{ marginBottom: "1rem" }}>
            The most current version of this EULA is posted at https://app.helixco.io/terms
             and may be revised by Helix Software at any time without notice to You. Such 
             revisions become effective immediately upon posting, and it is Your responsibility
              to periodically review the EULA for updates.
          </Typography>
          <hr style={{ marginBottom: '1rem'}} />
          <Typography variant="h4" style={{ marginBottom: '1rem'}}>
            2. Access and Use
          </Typography>
          <Typography variant="body1" style={{ marginBottom: "1rem" }}>
            For so long as Helix Software determines, We grant You a <b>non-exclusive, personal, 
            non-transferable, non-sublicensable, revocable, and limited</b> right to access and use 
            certain functionality of the SaaS Platform strictly for Your employer's commercial 
            transactions and no other purpose (the "Permitted Use").
          </Typography>
          <Typography variant="h5" style={{marginBottom: '1rem'}}>
            Access Conditions
          </Typography>
          <Typography variant="body1" style={{ marginBottom: "1rem" }}>
            <ul style={{ listStyleType: 'disc', marginLeft: '2rem' }}>
              <li style={{ marginBottom: '.5em'}}>
                Your access is contingent upon <b>Your employer’s authorization</b> and remains subject 
                to <b>compliance with this EULA.</b>
              </li>
              <li style={{ marginBottom: '.5em'}}>
                If Your employment ends or Your employer revokes access, You are no longer authorized 
                to use the SaaS Platform.
              </li>
              <li style={{ marginBottom: '.5em'}}>
                You are solely responsible for maintaining security over Your account credentials 
                and ensuring that access is not shared.
              </li>
            </ul>
          </Typography>
          <Typography variant="h5" style={{marginBottom: '1rem'}}>
            Restrictions on Use
          </Typography>
          <Typography variant="body1" style={{ marginBottom: "1rem" }}>
            You may <b>not:</b>
            <ul style={{ listStyleType: 'disc', marginLeft: '2rem', marginTop: '.5rem' }}>
              <li style={{ marginBottom: '.5em'}}>Sell, sublicense, rent, lease, or transfer access to the SaaS Platform.</li>
              <li style={{ marginBottom: '.5em'}}>Reverse engineer, decompile, disassemble, or otherwise attempt to extract source code.</li>
              <li style={{ marginBottom: '.5em'}}>Modify, translate, or create derivative works based on the SaaS Platform.</li>
              <li style={{ marginBottom: '.5em'}}>Use the SaaS Platform in a manner that violates <b>any applicable law</b> or <b>third-party rights.</b></li>
              <li style={{ marginBottom: '.5em'}}>Interfere with or disrupt the integrity or security of the SaaS Platform.</li>
              <li style={{ marginBottom: '.5em'}}>Use the SaaS Platform to develop, assist in developing, or market a competing product.</li>
              <li style={{ marginBottom: '.5em'}}>Attempt to gain unauthorized access to Helix Software's <b>network, systems, or any customer data.</b></li>
            </ul>
          </Typography>
          <Typography variant="body1" style={{ marginBottom: "1rem" }}>
            Helix Software <b>reserves the right</b> to suspend or terminate Your access at any time <b>without notice, 
            liability, or reason</b>, including violations of this EULA.
          </Typography>
          <hr style={{ marginBottom: '1rem'}} />
          <Typography variant="h4" style={{ marginBottom: "1rem" }}>
            3. Subscription Fees, Payment Terms, and Auto-Renewal
          </Typography>
          <Typography variant="body1" style={{ marginBottom: '1rem' }}>
            <ul style={{ listStyleType: 'disc', marginLeft: '2rem', marginTop: '.5rem' }}>
                <li style={{ marginBottom: '.5em'}}>Fees are specified in the Helix Co <b>Subscription Order Form</b> and are <b>due upon receipt.</b></li>
                <li style={{ marginBottom: '.5em'}}>A <b>1.5% monthly late fee</b> may be applied to past-due balances.</li>
                <li style={{ marginBottom: '.5em'}}>Subscriptions <b>automatically renew</b> unless You provide <b>written notice of cancellation at least 30 days before renewal.</b></li>
                <li style={{ marginBottom: '.5em'}}>Helixco reserves the right to adjust pricing annually by up to <b>5%</b> upon renewal.</li>
              </ul>
          </Typography>
          <hr style={{ marginBottom: '1rem'}} />
          <Typography variant="h4" style={{ marginBottom: "1rem" }}>
            4. Service Level Agreement (SLA)
          </Typography>
          <Typography variant="body1" style={{ marginBottom: '1rem' }}>
            <ul style={{ listStyleType: 'disc', marginLeft: '2rem', marginTop: '.5rem' }}>
                <li style={{ marginBottom: '.5em'}}>Helix Software guarantees <b>99% uptime</b> for the SaaS Platform.</li>
                <li style={{ marginBottom: '.5em'}}>If uptime falls below this threshold, customers may receive a <b>pro-rated service credit</b> against future fees.</li>
                <li style={{ marginBottom: '.5em'}}><b>No service credits will be issued</b> for downtime caused by <b>customer misuse, 
                  internet outages, force majeure events, or factors beyond</b> Helix Software’s <b>reasonable control.</b></li>
                <li style={{ marginBottom: '.5em'}}><b>Service credits are the sole and exclusive remedy</b> for uptime failures.</li>
              </ul>
          </Typography>
          <hr style={{ marginBottom: '1rem'}} />
          <Typography variant="h4" style={{ marginBottom: "1rem" }}>
            5. Data Security, Confidentiality, and Protection
          </Typography>
          <Typography variant="body1" style={{ marginBottom: '1rem' }}>
            <ul style={{ listStyleType: 'disc', marginLeft: '2rem', marginTop: '.5rem' }}>
                <li style={{ marginBottom: '.5em'}}>Helix Software <b>employs industry-standard security measures</b>, including <b>blockchain and smart contract methodology</b>, encryption, and <b>access control mechanisms</b>.</li>
                <li style={{ marginBottom: '.5em'}}>Helix Software <b>does not sell, share, or monetize customer data</b> outside of the <b>Permitted Use</b>.</li>
                <li style={{ marginBottom: '.5em'}}>Customers <b>must implement</b> internal security policies to protect data entered into the SaaS Platform.</li>
                <li style={{ marginBottom: '.5em'}}>Helix Software <b>assumes no liability</b> for unauthorized access caused by compromised user credentials.</li>
            </ul>
          </Typography>
          <Typography variant="h5" style={{marginBottom: '1rem'}}>
            Prohibited Data Storage
          </Typography>
          <Typography variant="body1" style={{ marginBottom: "1rem" }}>
            You may not transmit, upload, or store <b>high-risk data</b>, including but not limited to:
            <ul style={{ listStyleType: 'disc', marginLeft: '2rem', marginTop: '.5rem' }}>
              <li style={{ marginBottom: '.5em'}}>Credit card details subject to PCI-DSS.</li>
              <li style={{ marginBottom: '.5em'}}>Personally Identifiable Information (PII) subject to GDPR, CCPA, or similar privacy laws.</li>
            </ul>
          </Typography>
          <hr style={{ marginBottom: '1rem'}} />
          <Typography variant="h4" style={{ marginBottom: "1rem" }}>
            6. Intellectual Property Rights
          </Typography>
          <Typography variant="body1" style={{ marginBottom: '1rem' }}>
            <ul style={{ listStyleType: 'disc', marginLeft: '2rem', marginTop: '.5rem' }}>
                <li style={{ marginBottom: '.5em'}}>Helix Software <b>retains exclusive ownership</b> of all rights, including intellectual property, software code, processes, and workflows related to the SaaS Platform.</li>
                <li style={{ marginBottom: '.5em'}}><b>You acquire no ownership interest</b> in the SaaS Platform.</li>
                <li style={{ marginBottom: '.5em'}}>Any feedback or suggestions provided by You <b>may be used</b> by Helix Software for future improvements <b>without compensation</b>.</li>
            </ul>
          </Typography>
          <hr style={{ marginBottom: '1rem'}} />
          <Typography variant="h4" style={{ marginBottom: "1rem" }}>
            7. Warranty Disclaimer and Limitation of Liability
          </Typography>
          <Typography variant="body1" style={{ marginBottom: '1rem' }}>
            <ul style={{ listStyleType: 'disc', marginLeft: '2rem', marginTop: '.5rem' }}>
                <li style={{ marginBottom: '.5em'}}>The <b>SaaS Platform is provided “as is” and without warranty</b> of any kind, express or implied.</li>
                <li style={{ marginBottom: '.5em'}}>Helix Software <b>disclaims all warranties</b>, including but not limited to implied warranties of <b>merchantability, non-infringement, and fitness for a particular purpose</b>.</li>
                <li style={{ marginBottom: '.5em'}}>Helix Software is <b>not liable for any indirect, incidental, punitive, or consequential damages</b> arising from Your use of the SaaS Platform.</li>
                <li style={{ marginBottom: '.5em'}}>In no event shall Helix Software liability exceed the <b>total amount paid</b> by You in the <b>12-month period preceding the claim</b>.</li>
            </ul>
          </Typography>
          <hr style={{ marginBottom: '1rem'}} />
          <Typography variant="h4" style={{ marginBottom: "1rem" }}>
            8. Indemnification
          </Typography>
          <Typography variant="body1" style={{ marginBottom: '1rem' }}>
            You agree to <b>indemnify, defend, and hold harmless</b> Helix Software from <b>any claims, liabilities, damages, and expenses arising</b> from:
            <ul style={{ listStyleType: 'disc', marginLeft: '2rem', marginTop: '.5rem' }}>
                <li style={{ marginBottom: '.5em'}}><b>Your misuse of the SaaS Platform</b>.</li>
                <li style={{ marginBottom: '.5em'}}><b>Any breach of this EULA</b>.</li>
                <li style={{ marginBottom: '.5em'}}><b>Any violation of third-party rights</b>.</li>
            </ul>
          </Typography>
          <hr style={{ marginBottom: '1rem'}} />
          <Typography variant="h4" style={{ marginBottom: "1rem" }}>
            9. Governing Law and General Provisions
          </Typography>
          <Typography variant="body1" style={{ marginBottom: '1rem' }}>
            <ul style={{ listStyleType: 'disc', marginLeft: '2rem', marginTop: '.5rem' }}>
                <li style={{ marginBottom: '.5em'}}>This EULA is governed by the <b>laws of the State of Ohio</b>, without regard to conflict of law principles.</li>
                <li style={{ marginBottom: '.5em'}}>Any disputes arising out of this EULA shall be resolved <b>exclusively in the state or federal courts of Ohio</b>.</li>
                <li style={{ marginBottom: '.5em'}}><b>If any provision of this EULA is deemed unenforceable</b>, the remaining provisions <b>shall remain in full force</b>.</li>
                <li style={{ marginBottom: '.5em'}}>Helix Software’s failure to enforce any provision <b>shall not constitute a waiver</b>.</li>
            </ul>
          </Typography>
          <hr style={{ marginBottom: '1rem'}} />
          <Typography variant="h4" style={{ marginBottom: "1rem" }}>
            10. Acceptance of Terms
          </Typography>
          <Typography variant="body1" style={{ marginBottom: '1rem' }}>
            By accessing or using the SaaS Platform, <b>You acknowledge that You have read, understood, and agree to abide by this EULA</b>.
          </Typography>
          <Typography variant="body1" style={{ marginBottom: '1rem' }}>
            For additional information, refer to Your <b>Helixco Subscription Order Form</b> or contact <b>support@helixco.io</b>.
          </Typography>
        </Styles>
      </Layout>
    </div>
  );
};

Terms.propTypes = {};

export default Terms;
