import React, {useState} from "react";
import {
  Slide,
  Backdrop,
  IconButton,
  Portal,
  Typography,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import constants from "components/constants";

import { DefaultLoader } from "common/Loader";
import { Container, Wrapper, Header } from "./Styled";
import { CircularProgress } from "@mui/material";
import styled from "styled-components";

const Closing = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: ${constants.colorBlueLightFade}99;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 99,
`;

const Snapshot = ({ title, onClick, loading, children, header, actions, style }) => {
  const [closing, setClosing] = useState(false);

  const close = () => {
    setClosing(true);
    onClick();
  }

  return (
    <Portal>
      <Wrapper>
        <Backdrop open={true} onClick={close} />
        <Slide in={true} mountOnEnter unmountOnExit direction="up">
          <Container style={style}>
            <Header>
              <Typography variant="h6">
                {loading ? "Loading..." : title}
              </Typography>
              <div>
                {!loading && actions}
                <IconButton onClick={close} size="large">
                  <Close style={{ color: "white" }} />
                </IconButton>
              </div>
            </Header>
            {loading ? (
              <DefaultLoader />
            ) : (
              <>
                {header}
                {children}
                {closing &&
                  <Closing>
                    <CircularProgress />
                  </Closing>}
              </>
            )}
          </Container>
        </Slide>
      </Wrapper>
    </Portal>
  );
};

Snapshot.propTypes = {};

export default Snapshot;
