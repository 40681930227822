import {
  createAsyncThunk,
} from "@reduxjs/toolkit";
import axios from "axios";

import { tokenConfig } from "actions/authActions";
import config from "config";

const namespace = "uploader";

export const fetchAWSToken = createAsyncThunk(
  `${namespace}/fetchAWSToken`,
  async ({ params }, { getState, rejectWithValue, dispatch }) => {
    try {
       const response = await axios.post(
          `${config.api_url}/aws-token`,
          params,
          tokenConfig(getState)
        );

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const moveAWSTempFile = createAsyncThunk(
  `${namespace}/moveAWSTempFile`,
  async ({ params }, { getState, rejectWithValue, dispatch }) => {
    try {
      const response = await axios.post(
        `${config.api_url}/aws-move-temp`,
        params,
        tokenConfig(getState)
      );
      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);
