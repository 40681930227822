import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useSelector } from "react-redux";
import Uppy from "@uppy/core";
import XHRUpload from "@uppy/xhr-upload";
import DragDrop from "@uppy/drop-target";
import ThumbnailGenerator from "@uppy/thumbnail-generator";
import { toArray } from "lodash";

import { formatFile, getPercentage } from "../../utility";
import config from "../../config";
import FileItem from "./FileItem";

import "@uppy/core/dist/style.css";
import "@uppy/drag-drop/dist/style.css";
import "@uppy/dashboard/dist/style.css";
import "./uploader-styles.scss";

const TextEditorUploader = forwardRef(
  (
    {
      entity,
      bundle,
      field,
      initialValues,
      onUploadSuccess,
      onUploadStart,
      onUploadFail,
      onRemove,
      onComplete,
      maxFileSize,
    },
    ref
  ) => {
    const [filesState, _setFiles] = useState({});
    const [errorMessage, setErrorMessage] = useState(null);
    const [formDataState, _setFormData] = useState({});
    const auth = useSelector((state) => state.auth.token);
    const csrf = useSelector((state) => state.auth.csrf);
    const filesRef = useRef(filesState);
    const formDataRef = useRef(formDataState);

    const setFiles = (data) => {
      filesRef.current = data;
      _setFiles(data);
    };

    const setFormData = (data) => {
      formDataRef.current = data;
      _setFormData(data);
    };

    const [uppy] = useState(() => {
      return new Uppy({
        autoProceed: true,
        restrictions: {
          allowedFileTypes: ["image/*", ".gif", ".png"],
          maxFileSize: maxFileSize ? maxFileSize : 5000000,
        },
        onBeforeFileAdded: (currentFile, files) => {
        //  Strip any Microsoft hyphens that may have snuck in.
          const newName = currentFile.name.replace(/–/g, '_');
          const modifiedFile = {
            ...currentFile,
            name: newName,
          }
          return modifiedFile
        },
      })
        .use(XHRUpload, {
          formData: false,
          headers: (file) => ({
            "X-CSRF-Token": csrf,
            "Content-Type": "application/octet-stream",
            "Content-Disposition": `file; filename="${encodeURIComponent(file.name)}"`,
            Authorization: `Bearer ${auth}`,
          }),
          endpoint: `${config.api_url}/file/upload/${entity}/${bundle}/${field}`,
        })
        .use(DragDrop, {
          target: document.body,
        })
        .use(ThumbnailGenerator, {
          thumbnailWidth: 200,
          waitForThumbnailsBeforeUpload: false,
        })
        .on("file-added", (file) => {
          const files = filesRef.current;
          const formData = formDataRef.current;
          setFiles({ ...files, [file.id]: file });
          setFormData({ ...formData, [file.id]: initialValues });
        })
        .on("thumbnail:generated", (file, preview) => {
          const files = filesRef.current;
          setFiles({ ...files, [file.id]: file });
        })
        .on("upload", (uploadID, files ) => {
          const _files = filesRef.current;
          const currentFiles = { ..._files };
          files.forEach((file) => currentFiles[file.id].progress.uploadStarted = true);

          onUploadStart();
          setFiles({ ...currentFiles });
          setErrorMessage(null);
        })
        .on("upload-progress", (file, progress) => {
          const files = filesRef.current;
          const percentage = getPercentage(
            progress.bytesUploaded,
            progress.bytesTotal
          );
          files[file.id].progress.bytesTotal = progress.bytesTotal;
          files[file.id].progress.bytesUploaded = progress.bytesUploaded;
          files[file.id].progress.percentage = percentage;
          setFiles({ ...files });
        })
        .on("upload-success", (file, response) => {
          const files = filesRef.current;
          const fileData = formatFile({ data: response.body });
          file.progress.uploadComplete = true;
          file.fileData = fileData;
          setFiles({ ...files, [file.id]: file });

          onUploadSuccess(fileData.fid);
        })
        .on("upload-error", (file, error, response) => {
          const files = filesRef.current;
          file.error = error;

          onUploadFail();
          setFiles({ ...files, [file.id]: file });
          setErrorMessage(error.message);
        })
        .on("upload-retry", (fileID) => {
          const files = filesRef.current;
          files[fileID].progress.uploadStarted = true;
          files[fileID].error = null;
          files[fileID].progress.bytesUploaded = 0;
          files[fileID].progress.percentage = 0;

          setFiles({ ...files });
          setErrorMessage(null)
        })
        .on("complete", (result) => {
          onComplete(result);
        })
        .on('restriction-failed', (file, error) => {
          setErrorMessage(error.message)
        });
    });

    const handleRetryUpload = (id) => {
      uppy.retryUpload(id);
    };

    const handleRemove = (id) => {
      const files = filesRef.current;
      const currentFields = { ...formDataState };
      delete files[id];
      delete currentFields[id];

      onRemove(id);
      setFormData({ ...currentFields });
      setFiles({ ...files });
      uppy.removeFile(id);
    };

    useImperativeHandle(ref, () => ({
      reset() {
        setFormData({});
        setFiles({});
        uppy.clear();
      },
    }));

    return (
      <div
        id="drop-area"
        style={{
          width: "100%",
          position: "relative",
          display: "flex",
          alignItems: "center",
        }}
      >
        {toArray(filesState).map((file) => (
          <div style={{ padding: "0.625rem", width: 120 }}>
            <FileItem
              file={file}
              acquirers={[]}
              retryUpload={() => handleRetryUpload(file.id)}
              onRemove={() => handleRemove(file.id)}
              meta={formDataState[file.id]}
              showFileInfo={false}
            />
          </div>
        ))}
        {/* <StatusBar uppy={uppy} hideUploadButton hideRetryButton hideAfterFinish /> */}
        {errorMessage !== null &&
          <div className="uppy-error-message-wrapper"><div className="message">{errorMessage}</div></div>
        }
      </div>
    );
  }
);

TextEditorUploader.propTypes = {};

export default TextEditorUploader;
