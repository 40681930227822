import React, { useMemo, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Grid, Typography } from "@mui/material";

import Button from "common/Button";
import { TextFormField } from "common/Fields";
import { SelectFormField } from "common/Fields";
import AutocompleteFormField from "components/AutocompleteFormField";
import { CheckboxFormField } from "common/Fields";
import { SingleUploader } from "common/Uploader";

import {
  memberStatusSelectors,
  regionsSelectors,
  memberAffiliationsSelectors,
  certificationsSelectors,
  jobDivisionTypesSelectors,
} from "features/Taxonomies/taxonomiesSlice";
import { size } from "lodash";
import TMTermsFormComponent from "components/TMTerms/forms/components/TMTermsFormComponent";
import { nationalContractsSelectors } from "features/Taxonomies/taxonomiesSlice";
import { filterNationalContracts, TaxonomyChip } from "features/Taxonomies/utils";
import ClientAssignedFieldAddEdit from "components/ClientAssignedField/ClientAssignedFieldAddEdit";
import ColorPicker from "components/ColorPicker";

const Information = ({
  values,
  isSubmitting,
  value,
  handleNext,
  setUploading,
  setFileId,
  setFieldValue,
  validateField,
  errors,
}) => {
  const statuses = useSelector(memberStatusSelectors.selectAll);
  const regions = useSelector(regionsSelectors.selectAll);
  const affiliations = useSelector(memberAffiliationsSelectors.selectAll);
  const user = useSelector((state) => state.auth.user.data);
  const clientNids = values.field_phoenix_clients;
  const certifications = useSelector(certificationsSelectors.selectAll);
  const services = useSelector(jobDivisionTypesSelectors.selectAll);
  const allNationalContracts = useSelector(nationalContractsSelectors.selectAll);
  const _clientNids = clientNids.map((client) => String(client.nid));
  const [nationalContracts, setNationalContracts] = useState(filterNationalContracts(allNationalContracts, _clientNids));

  useEffect(() => {
    const _clientNids = clientNids.map((client) => String(client.nid));
    setNationalContracts(filterNationalContracts(allNationalContracts, _clientNids));
    setFieldValue("field_national_contracts", []);
  }, [clientNids, allNationalContracts, setFieldValue])

  const showMemberCode = useMemo(() => {
    const numClients = size(values.field_phoenix_clients);
    const index = values.field_phoenix_clients.findIndex((client) => {
      return client.name.includes("DKI");
    });

    return numClients > 1 || index < 0;
  }, [values.field_phoenix_clients]);

  const handleUploadStart = () => {
    setUploading(true);
    setFileId(null);
  };

  const handleUploadSuccess = (fid) => {
    setUploading(false);
    setFileId(fid);
  };

  const handleUploadFail = () => {
    setUploading(false);
    setFileId(null);
  };

  const handleRemoveFile = () => {
    setFileId(null);
  };

  return (
    <div hidden={value !== 0}>
      <Typography variant="h6">Information</Typography>
      <Typography
        variant="body1"
        color="textSecondary"
        style={{ marginBottom: "2rem" }}
      >
        Create a new Provider to grant access to job assignment, compliance
        tracking, and other system based benefits.
      </Typography>
      <Grid container spacing={2}>
        <Grid item xxs={12}>
          <TextFormField
            fullWidth
            name="title"
            label="Name"
            variant="outlined"
            size="small"
            required
            disabled={isSubmitting}
          />
        </Grid>
        {size(user.field_clients) > 1 && (
          <Grid item xxs={12}>
            <AutocompleteFormField
              fullWidth
              name="field_phoenix_clients"
              label="Clients"
              variant="outlined"
              size="small"
              required
              multiple
              disabled={isSubmitting}
              options={user.field_clients.map((obj) => ({
                nid: obj.nid,
                name: obj.field_pc_proper_name,
              }))}
              helperText=""
            />
          </Grid>
        )}
        {(user?._processed?.access_managing_phoenix_client && size(user.field_clients) > 1) && (
          <Grid item xxs={12}>
            <AutocompleteFormField
              fullWidth
              name="field_managing_phoenix_clients"
              label="Managing Clients"
              variant="outlined"
              size="small"
              required
              multiple
              disabled={isSubmitting}
              options={user.field_clients.map((obj) => ({
                nid: obj.nid,
                name: obj.field_pc_proper_name,
              }))}
              helperText="Which clients can make edits to the Provider record?"
            />
          </Grid>
        )}
        <Grid item xxs={6}>
          <SingleUploader
            entity="node"
            bundle="member"
            field="field_logo"
            inputLabel="Logo"
            label="Select Logo"
            onUploadSuccess={handleUploadSuccess}
            onUploadStart={handleUploadStart}
            onUploadFail={handleUploadFail}
            onRemove={handleRemoveFile}
            imageFileTypes
          />
        </Grid>
        <Grid item xxs={6}>
          <div className="flex-col pt-5">
            <Typography variant="subtitle1" style={{ marginBottom: "1rem" }}>
              Select Banner Color
            </Typography>
            <ColorPicker saveLogic={setFieldValue} defaultColor={values.field_highlight_color} fieldName='field_highlight_color'/>
          </div>
        </Grid>
        <Grid item xxs={12} md={showMemberCode ? 4 : 6}>
          <TextFormField
            fullWidth
            name="field_office_id"
            label="Office ID"
            variant="outlined"
            size="small"
            required
            disabled={isSubmitting}
          />
        </Grid>
        <Grid item xxs={12} md={showMemberCode ? 4 : 6}>
          <TextFormField
            fullWidth
            name="field_next_job_number"
            label="Next job number"
            variant="outlined"
            size="small"
            required
            disabled={isSubmitting}
          />
        </Grid>
        {showMemberCode && (
          <Grid item xxs={12} md={4}>
            <TextFormField
              fullWidth
              name="field_member_code"
              label="Member Code"
              variant="outlined"
              size="small"
              required
              disabled={isSubmitting}
              helperText="Custom job number code"
            />
          </Grid>
        )}
        <Grid item xxs={12} md={showMemberCode ? 4 : 4}>
          <SelectFormField
            fullWidth
            name="field_status"
            label="Status"
            variant="outlined"
            size="small"
            required
            disabled={isSubmitting}
            options={statuses.map((obj) => ({
              value: obj.machine_name,
              label: obj.label,
            }))}
          />
        </Grid>
        <Grid item xxs={12} md={4}>
          <SelectFormField
            fullWidth
            name="field_region"
            label="Region"
            variant="outlined"
            size="small"
            required
            disabled={isSubmitting}
            options={regions.map((obj) => ({
              value: obj.tid,
              label: obj.name,
            }))}
          />
        </Grid>
        <Grid item xxs={12} md={4}>
          <SelectFormField
            fullWidth
            name="field_affiliation"
            label="Affiliation"
            variant="outlined"
            size="small"
            required
            disabled={isSubmitting}
            options={affiliations.map((obj) => ({
              value: obj.tid,
              label: obj.name,
            }))}
          />
        </Grid>
        <Grid item xxs={12} md={6}>
          <ClientAssignedFieldAddEdit
            // entity={member}
            clients={values.field_phoenix_clients}
            field="field_jd_types"
            label="Services"
            values={values}
            setFieldValue={setFieldValue}
            options={services}
            optionsSelectors={jobDivisionTypesSelectors}
            variant="outlined"
            isSubmitting={isSubmitting}
            // emptyMessage={
            //   <TextFormField
            //     fullWidth
            //     name="field_jd_types"
            //     label="Services (select a Client above)"
            //     margin="normal"
            //     multiple
            //     variant="outlined"
            //     size="small"
            //     disabled={true}
            //   />
            // }
            selectChip={(value, i, field, optionsSelectors) => (
              <TaxonomyChip
                tid={value}
                key={`client-assigned-${field}-${i}`}
                selectors={optionsSelectors}
              />
            )}
          />
        </Grid>
        <Grid item xxs={12} md={6}>
          <SelectFormField
            fullWidth
            name="field_certifications"
            label="Certifications"
            margin="normal"
            multiple
            variant="outlined"
            size="small"
            disabled={isSubmitting}
            renderValue={(selected) => (
              <div
                style={{
                  minHeight: "10px",
                  display: "flex",
                  flexWrap: "wrap",
                }}
              >
                {selected.map((value, i) => (
                  <TaxonomyChip
                    tid={value}
                    key={`cert-${i}`}
                    selectors={certificationsSelectors}
                  />
                ))}
              </div>
            )}
            options={certifications.map((cert) => ({
              label: cert.name,
              value: cert.tid,
            }))}
          />
        </Grid>
        <Grid item xxs={12} md={4}>
          <TextFormField
            fullWidth
            name="field_response_time"
            label="Response Time"
            disabled={isSubmitting}
            variant="outlined"
            size="small"
          />
        </Grid>
        <Grid item xxs={12} md={4}>
          <TextFormField
            fullWidth
            name="field_website"
            label="Website"
            disabled={isSubmitting}
            variant="outlined"
            size="small"
          />
        </Grid>
        <Grid item xxs={12} md={4}>
          <TextFormField
            fullWidth
            name="field_video_call_url"
            label="Meeting"
            disabled={isSubmitting}
            variant="outlined"
            size="small"
          />
        </Grid>
        {size(nationalContracts) > 0 && (
          <Grid item xxs={12}>
            <SelectFormField
              fullWidth
              name="field_national_contracts"
              label="National Contracts"
              variant="outlined"
              size="small"
              disabled={isSubmitting}
              multiple
              options={nationalContracts.map((obj) => ({
                value: obj.tid,
                label: obj.name,
              }))}
              renderValue={(selected) => (
                <div
                  style={{
                    minHeight: "10px",
                    display: "flex",
                    flexWrap: "wrap",
                  }}
                >
                  {selected.map((value) => (
                    <TaxonomyChip
                      key={value}
                      tid={value}
                      selectors={nationalContractsSelectors}
                    />
                  ))}
                </div>
              )}
            />
          </Grid>
        )}
        <Grid item xxs={12}>
          <TextFormField
            fullWidth
            name="field_note"
            label="Notes (internal use only)"
            multiline
            disabled={isSubmitting}
            variant="outlined"
            size="small"
          />
        </Grid>
        <Grid item xxs={12}>
          <CheckboxFormField
            name="field_is_parent_member"
            label="Is A Parent Provider"
          />
          <CheckboxFormField name="field_g2" label="G2 Provider" />
          <CheckboxFormField
            name="field_d2d"
            label="Commercial Day-to-Day Provider"
          />
          <CheckboxFormField
            name="field_daily_sheets_member"
            label="Daily Sheets Member"
          />
        </Grid>
        <Grid item xxs={12}>
          <Grid container spacing={3}>
            <TMTermsFormComponent
              errors={errors}
              setFieldValue={setFieldValue}
              isSubmitting={isSubmitting}
            />
          </Grid>
        </Grid>
        <Grid item xxs={12}>
          <div
            style={{
              marginTop: "2rem",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              variant="contained"
              color="secondary"
              type="submit"
              disabled={isSubmitting}
              disableElevation
              size="small"
              onClick={handleNext}
            >
              Next
            </Button>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

Information.propTypes = {};

export default Information;
