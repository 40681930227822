import React from "react";
import { CircularProgress, } from "@mui/material";

function onPauseResumeCancelRetry(props) {
  if (props.isUploaded) return;

  if (props.error && !props.hideRetryButton) {
    props.retryUpload(props.file.id);
    return;
  }

  if (props.resumableUploads && !props.hidePauseResumeButton) {
    props.pauseUpload(props.file.id);
  } else if (props.individualCancellation && !props.hideCancelButton) {
    props.cancelUpload(props.file.id);
  }
}

function ProgressIndicatorButton(props) {
  return (
    <div
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        zIndex: 3,
        color: "white",
        textAlign: "center",
        width: "120px",
        transition: "all .35s ease",
      }}
    >
      <button
        style={{
          display: "inline-block",
          width: "38px",
          height: "38px",
          opacity: "0.9",
        }}
        className="uppy-u-reset"
        type="button"
        onClick={() => onPauseResumeCancelRetry(props)}
      >
        {props.children}
      </button>
    </div>
  );
}

function ProgressCircleContainer({ children }) {
  return (
    <svg
      aria-hidden="true"
      focusable="false"
      width="70"
      height="70"
      viewBox="0 0 36 36"
      className="uppy-c-icon"
      style={{
        width: "100%",
        height: "100%",
      }}
    >
      {children}
    </svg>
  );
}

function ProgressCircle({ progress }) {
  // circle length equals 2 * PI * R
  const circleLength = 2 * Math.PI * 15;

  return (
    <g>
      <circle
        style={{
          stroke: "rgba(white, 0.4)",
        }}
        r="15"
        cx="18"
        cy="18"
        strokeWidth="2"
        fill="none"
      />
      <circle
        style={{
          stroke: "var(--color-orange)",
          transition: "stroke-dashoffset .5s ease-out",
        }}
        r="15"
        cx="18"
        cy="18"
        transform="rotate(-90, 18, 18)"
        strokeWidth="2"
        fill="none"
        strokeDasharray={circleLength}
        strokeDashoffset={circleLength - (circleLength / 100) * progress}
      />
    </g>
  );
}

const FileProgress = (props) => {
  // Nothing if upload has not started

  if (!props.file.progress.uploadStarted) {
    return null;
  }

  // Green checkmark when complete
  if (props.isUploaded) {
    return (
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          zIndex: 3,
          color: "white",
          textAlign: "center",
          width: "120px",
          transition: "all .35s ease",
        }}
      >
        <div
          style={{
            display: "inline-block",
            width: "38px",
            height: "38px",
            opacity: "0.9",
          }}
        >
          <ProgressCircleContainer>
            <circle r="15" cx="18" cy="18" fill="#1bb240" />
            <polygon
              style={{
                fill: "white",
                transition: "all 0.2s",
              }}
              transform="translate(2, 3)"
              points="14 22.5 7 15.2457065 8.99985857 13.1732815 14 18.3547104 22.9729883 9 25 11.1005634"
            />
          </ProgressCircleContainer>
        </div>
      </div>
    );
  }

  // Retry button for error
  if (props.error && !props.hideRetryButton) {
    return (
      <ProgressIndicatorButton {...props}>
        <svg
          aria-hidden="true"
          focusable="false"
          className="uppy-c-icon"
          style={{
            fill: "yellow",
          }}
          width="28"
          height="31"
          viewBox="0 0 16 19"
        >
          <path d="M16 11a8 8 0 1 1-8-8v2a6 6 0 1 0 6 6h2z" />
          <path d="M7.9 3H10v2H7.9z" />
          <path d="M8.536.5l3.535 3.536-1.414 1.414L7.12 1.914z" />
          <path d="M10.657 2.621l1.414 1.415L8.536 7.57 7.12 6.157z" />
        </svg>
      </ProgressIndicatorButton>
    );
  }

  // Cancel button for non-resumable uploads if individualCancellation is supported (not bundled)
  if (
    !props.resumableUploads &&
    props.individualCancellation &&
    !props.hideCancelButton
  ) {
    return (
      <ProgressIndicatorButton {...props}>
        <ProgressCircleContainer>
          <ProgressCircle progress={props.file.progress.percentage} />
          <polygon
            className="cancel"
            transform="translate(2, 2)"
            points="19.8856516 11.0625 16 14.9481516 12.1019737 11.0625 11.0625 12.1143484 14.9481516 16 11.0625 19.8980263 12.1019737 20.9375 16 17.0518484 19.8856516 20.9375 20.9375 19.8980263 17.0518484 16 20.9375 12"
          />
        </ProgressCircleContainer>
      </ProgressIndicatorButton>
    );
  }

  // Just progress when buttons are disabled
  return (
    <div
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        zIndex: 3,
        color: "white",
        textAlign: "center",
        width: "120px",
        transition: "all .35s ease",
      }}
    >
      <div
        style={{
          display: "inline-block",
          width: "38px",
          height: "38px",
          opacity: "0.9",
        }}
      >
        <ProgressCircleContainer>
          <ProgressCircle progress={props.file.progress.percentage} />
        </ProgressCircleContainer>
        <CircularProgress size="20px" className="absolute m-auto  top-0 bottom-0 left-0 right-0 text-white" />
      </div>
    </div>
  );
};

export default FileProgress;
