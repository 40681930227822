import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector, connect } from "react-redux";
import { Link, useLocation, useParams, useRouteMatch } from "react-router-dom";
import { rrulestr } from "rrule";
import moment from "moment";
import { size } from "lodash";
import {
  Typography,
  IconButton,
  Tooltip,
  Button,
  Chip,
} from "@mui/material";
import {
  Edit,
  CheckCircle,
  ErrorOutline,
  NotificationsActive,
  Replay,
  Add,
} from "@mui/icons-material";

import {
  Container,
  Actions,
  Chips,
  Footer,
  Priority,
  Reminder,
} from "./Styled";
import { formatTask, formatMFMonitoringChecklist } from "../../utility";
import { TaskSkeleton } from "../Skeletons";
import { RouteDialog } from "../Dialog";
import { getTask, patchTask, completeTask } from "../../actions";
import TaskEdit from "./TaskRouteEdit";

const formatParagraphs = (d) =>
  d.map((paragraph) => formatMFMonitoringChecklist(paragraph));

const Content = ({
  url,
  loadTask,
  task,
  loading,
  processed={},
  delta,
  markComplete,
  setTitle,
  pathname,
  handleRefetchEvents,
  loadMFProgress,
}) => {
  const user = useSelector((state) => state.auth.user.data);
  const { id } = useParams();
  const taskFields = formatTask(task);
  const {
    allDay,
    body,
    complete,
    end,
    start,
    rrule,
    title,
    users,
    job,
    reminder,
    taskType,
    monitoringChecklists,
    priority: { name: prioName },
  } = taskFields;

  const checklists = formatParagraphs(monitoringChecklists);

  useEffect(() => {
    if (id) {
      loadTask(id);
    }
  }, [id, loadTask]);

  useEffect(() => {
    if (title) {
      setTitle(title);
    }

    return () => {
      setTitle("Task");
    };
  }, [title, setTitle]);

  const toggleComplete = async (e) => {
    if (!complete) {
      const params = {
        task_nid: id,
        completed: 1,
        recur_delta: delta,
      };

      const response = await markComplete(params);
      if (response.status === 200) {
        if (handleRefetchEvents) {
          handleRefetchEvents();
        }

        if (loadMFProgress) {
          loadMFProgress();
        }

        await loadTask(id);
      }
    }
  };

  return <>
    {loading ? (
      <div style={{ padding: "1.25rem" }}>
        <TaskSkeleton />
      </div>
    ) : (
      <>
        <Container>
          <Typography
            variant="subtitle1"
            color="textSecondary"
            style={{ marginBottom: "1rem" }}
          >
            {moment(start).format("MMM Do, YYYY")}
            {allDay ? "" : ` ${moment(start).format("h:mm A")}`}
            {end && !allDay
              ? ` - ${moment(end).format("MMM Do h:mm A")}`
              : ""}
          </Typography>
          {users && users.length > 0 && (
            <Chips>
              {users.map((user, index) => (
                <Chip
                  clickable
                  key={user.uid}
                  label={`${user.first} ${user.last}`}
                  size="small"
                  component={Link}
                  to={user.path}
                />
              ))}
            </Chips>
          )}
          <Typography variant="body1" style={{ marginBottom: "1rem" }}>
            {body}
          </Typography>
          {rrule && (
            <Reminder>
              <Replay color="inherit" style={{ marginRight: "0.5rem" }} />
              <Typography color="textPrimary">
                Repeats {rrulestr(rrule).toText()}.
              </Typography>
            </Reminder>
          )}
          {reminder && (
            <Reminder>
              <NotificationsActive
                color="inherit"
                style={{ marginRight: "0.5rem" }}
              />
              <Typography color="textPrimary">
                {`Notification ${reminder} before`}
              </Typography>
            </Reminder>
          )}
          {taskType.name === "Monitoring" && size(checklists) === 0 ? (
            <div>
              <Button
                disableElevation
                variant="outlined"
                component={Link}
                size="small"
                style={{ marginTop: "2rem" }}
                to={`${pathname}/add-monitoring`}
                startIcon={<Add />}>
                Monitoring
              </Button>
            </div>
          ) : size(checklists) > 0 ? (
            checklists.map((checklist) => (
              <div>
                <Button
                  disableElevation
                  variant="outlined"
                  component={Link}
                  size="small"
                  style={{ marginTop: "2rem" }}
                  to={`${pathname}/m/${checklist.id}`}>
                  Monitoring Form
                </Button>
              </div>
            ))
          ) : null}
          {job.title && (
            <Button
              color="secondary"
              disableElevation
              variant="contained"
              component={Link}
              size="small"
              style={{ marginTop: "2rem" }}
              to={`/job/${job.title.toLowerCase()}`}
            >
              {job.title}
            </Button>
          )}
        </Container>
        <Footer>
          <Priority>
            {prioName === "High" && (
              <>
                <ErrorOutline
                  color="inherit"
                  style={{ marginRight: "0.5rem" }}
                />
                <Typography variant="subtitle1" color="textSecondary">
                  Priorty
                </Typography>
              </>
            )}
          </Priority>
          <Actions>
            {processed.edit_access && (
              <>
                <Tooltip title="Complete">
                  <span>
                    <IconButton
                      size="small"
                      style={{ marginRight: "0.5rem" }}
                      disabled={complete}
                      onClick={toggleComplete}
                    >
                      <CheckCircle />
                    </IconButton>
                  </span>
                </Tooltip>
                <Tooltip title="Edit">
                  <IconButton
                    to={`${pathname}/edit`}
                    component={Link}
                    style={{ marginRight: "0.5rem" }}
                    size="small"
                  >
                    <Edit />
                  </IconButton>
                </Tooltip>
              </>
            )}
          </Actions>
        </Footer>
        <TaskEdit url={pathname} user={user} task={taskFields} />
      </>
    )}
  </>;
};

const Task = ({ processed={edit_access: false}, ...rest }) => {
  const { url } = useRouteMatch();
  const { pathname } = useLocation();
  const [title, setTitle] = React.useState("Task");

  return (
    <RouteDialog
      route={"t/:id"}
      path={`t`}
      url={url}
      maxWidth="xs"
      title={title}
      disableOverlay
    >
      <Content url={url} setTitle={setTitle} processed={processed} pathname={pathname} {...rest} />
    </RouteDialog>
  );
};

const { string } = PropTypes;
Task.propTypes = {
  url: string,
};

const mapStateToProps = (state) => ({
  task: state.app.task.data,
  loading: state.app.task.loading,
  processed: state.app.task.data._processed,
});

const mapDispatchToProps = (dispatch) => ({
  loadTask: (nid) => dispatch(getTask(nid)),
  updateTask: (nid, params) => dispatch(patchTask(nid, params)),
  markComplete: (params) => dispatch(completeTask(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Task);
