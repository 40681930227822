import React from "react";
import { first } from "lodash";
import {
  formatFieldByValue,
  getPathFromLinksSelf,
  formatField,
  getTaxonomyFields,
  getFileFields,
} from "../../utility";
//import Vapor from "laravel-vapor";
import Vapor from "utility/Vapor";
import config from "config";

export const formatJobDivisionFile = (d) => {
  return {
    self: getPathFromLinksSelf(d),
    // type: "paragraph/job_division_file",
    type: "node/general_file",
    id: formatField(d, "id"),
    nid: formatField(d, "nid"),
    revision_id: formatField(d, "revision_id"),
    title: formatField(d, "title"),
    created: formatField(d, "created"),
    changed: formatField(d, "changed"),
    path: formatFieldByValue(d, "path", "alias"),
    field_gf_amount: formatField(d, "field_gf_amount"),
    field_file: first(getFileFields(d, "field_file")),
    field_file_category: getTaxonomyFields(d, "field_file_category"),
    field_visibility: d.field_visibility
      ?.map((visible) => visible.value)
      .join(","),
    field_gf_photo_time: formatField(d, "field_gf_photo_time"),
    field_gf_uploader_name: formatField(d, "field_gf_uploader_name"),
    field_addl_ref: formatField(d, "field_addl_ref"),
    // field_jdf_da_bundle: d.reference_bundle,
    // field_jdf_title: formatField(d, "field_jdf_title"),
    field_gf_date: formatField(d, "field_gf_date"),
    field_published: formatField(d, "field_published"),
    field_public_hash: formatField(d, "field_public_hash"),
    field_original_file: d?.field_original_file ? first(getFileFields(d, "field_original_file")) : {},
    is_enhanced: Boolean(d?.field_original_file),
  };
};

export const formatCustomerFile = (d) => {
  return {
    self: getPathFromLinksSelf(d),
    // type: "paragraph/job_division_file",
    type: "node/general_file",
    id: String(formatField(d, "id")),
    revision_id: formatField(d, "revision_id"),
    title: formatField(d, "title"),
    created: formatField(d, "created"),
    changed: formatField(d, "changed"),
    path: formatFieldByValue(d, "path", "alias"),
    field_file: first(getFileFields(d, "field_cd_file")),
    field_cd_category: getTaxonomyFields(d, "field_cd_category"),
    field_visible_to: d.field_cd_visibility
      ?.map((visible) => visible.value)
      .join(","),
  };
};

export const formatJobDivisionFileListingItem = (d) => {
  return {
    id: (d?.parent_id ? d.parent_id : d.nid),
    title: d.title,
    job_file_type: d.job_file_type,
    field_file_category: { tid: d.category_tid, name: d.category_name },
    field_visibility: d?.visibility ? d.visibility?.split(",") : [],
    field_gf_photo_time: d.photo_time,
    field_addl_ref: d.reference_nid,
    field_jdf_da_bundle: d.reference_bundle,
    // field_jdf_title: d.title,
    field_gf_date: d.file_date,
    field_gf_uploader_name: d.uploader_name,
    field_gf_amount: d.amount,
    field_published: d.published,
    field_public_hash: d.hash,
    file_created: d.file_created,
    field_file: {
      fid: d.fid,
      thumb: d.medium,
      file_dl_token: d._file_dl_tokens?.file,
      uri: d._files?.file.replace("system/files", "private://"),
      filemime: d.filemime,
      filename: d.filename,
    },
    field_original_file: d?.original_fid
      ? {
        fid: d.original_fid,
        file_dl_token: d._file_dl_tokens?.original,
        uri: d._files?.original.replace("system/files", "private://"),
        filemime: d.original_filemime,
        filename: d.original_filename,
      }
      : {},
    is_enhanced: Number(d.is_enhanced) > 0,
  };
};

export const formatCustomerFileListingItem = (d) => {
  return {
    id: d.paragraph_pid,
    field_cd_category: { tid: d.category_tid, name: d.category_name },
    field_visible_to: d.visibility?.split(","),
    file_created: d.file_created,
    field_file: {
      fid: d.fid,
      thumb: d.thumb,
      file_dl_token: d._file_dl_tokens?.file,
      uri: d._files?.file.replace("system/files", "private://"),
      filemime: d.filemime,
      filename: d.filename,
    },
  };
};

export const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

export const downloadOctet = (url, filename) => {
  const link = document.createElement("a");
  link.href = url;
  link.download = filename;
  link.target = "_blank";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

function iconImage() {
  return (
    <svg
      ariaHidden="true"
      focusable="false"
      width="25"
      height="25"
      viewBox="0 0 25 25"
    >
      <g fill="white" fillRule="evenodd">
        <path
          d="M5 7v10h15V7H5zm0-1h15a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1z"
          fillRule="nonzero"
        />
        <path
          d="M6.35 17.172l4.994-5.026a.5.5 0 0 1 .707 0l2.16 2.16 3.505-3.505a.5.5 0 0 1 .707 0l2.336 2.31-.707.72-1.983-1.97-3.505 3.505a.5.5 0 0 1-.707 0l-2.16-2.159-3.938 3.939-1.409.026z"
          fillRule="nonzero"
        />
        <circle cx="7.5" cy="9.5" r="1.5" />
      </g>
    </svg>
  );
}

function iconAudio() {
  return (
    <svg
      ariaHidden="true"
      focusable="false"
      className="uppy-c-icon"
      width="25"
      height="25"
      viewBox="0 0 25 25"
    >
      <path
        d="M9.5 18.64c0 1.14-1.145 2-2.5 2s-2.5-.86-2.5-2c0-1.14 1.145-2 2.5-2 .557 0 1.079.145 1.5.396V7.25a.5.5 0 0 1 .379-.485l9-2.25A.5.5 0 0 1 18.5 5v11.64c0 1.14-1.145 2-2.5 2s-2.5-.86-2.5-2c0-1.14 1.145-2 2.5-2 .557 0 1.079.145 1.5.396V8.67l-8 2v7.97zm8-11v-2l-8 2v2l8-2zM7 19.64c.855 0 1.5-.484 1.5-1s-.645-1-1.5-1-1.5.484-1.5 1 .645 1 1.5 1zm9-2c.855 0 1.5-.484 1.5-1s-.645-1-1.5-1-1.5.484-1.5 1 .645 1 1.5 1z"
        fill="white"
        fillRule="nonzero"
      />
    </svg>
  );
}

function iconVideo() {
  return (
    <svg
      ariaHidden="true"
      focusable="false"
      className="uppy-c-icon"
      width="25"
      height="25"
      viewBox="0 0 25 25"
    >
      <path
        d="M16 11.834l4.486-2.691A1 1 0 0 1 22 10v6a1 1 0 0 1-1.514.857L16 14.167V17a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v2.834zM15 9H5v8h10V9zm1 4l5 3v-6l-5 3z"
        fill="white"
        fillRule="nonzero"
      />
    </svg>
  );
}

function iconPDF() {
  return (
    <svg
      ariaHidden="true"
      focusable="false"
      className="uppy-c-icon"
      width="25"
      height="25"
      viewBox="0 0 25 25"
    >
      <path
        d="M9.766 8.295c-.691-1.843-.539-3.401.747-3.726 1.643-.414 2.505.938 2.39 3.299-.039.79-.194 1.662-.537 3.148.324.49.66.967 1.055 1.51.17.231.382.488.629.757 1.866-.128 3.653.114 4.918.655 1.487.635 2.192 1.685 1.614 2.84-.566 1.133-1.839 1.084-3.416.249-1.141-.604-2.457-1.634-3.51-2.707a13.467 13.467 0 0 0-2.238.426c-1.392 4.051-4.534 6.453-5.707 4.572-.986-1.58 1.38-4.206 4.914-5.375.097-.322.185-.656.264-1.001.08-.353.306-1.31.407-1.737-.678-1.059-1.2-2.031-1.53-2.91zm2.098 4.87c-.033.144-.068.287-.104.427l.033-.01-.012.038a14.065 14.065 0 0 1 1.02-.197l-.032-.033.052-.004a7.902 7.902 0 0 1-.208-.271c-.197-.27-.38-.526-.555-.775l-.006.028-.002-.003c-.076.323-.148.632-.186.8zm5.77 2.978c1.143.605 1.832.632 2.054.187.26-.519-.087-1.034-1.113-1.473-.911-.39-2.175-.608-3.55-.608.845.766 1.787 1.459 2.609 1.894zM6.559 18.789c.14.223.693.16 1.425-.413.827-.648 1.61-1.747 2.208-3.206-2.563 1.064-4.102 2.867-3.633 3.62zm5.345-10.97c.088-1.793-.351-2.48-1.146-2.28-.473.119-.564 1.05-.056 2.405.213.566.52 1.188.908 1.859.18-.858.268-1.453.294-1.984z"
        fill="white"
        fillRule="nonzero"
      />
    </svg>
  );
}

function iconArchive() {
  return (
    <svg
      ariaHidden="true"
      focusable="false"
      width="25"
      height="25"
      viewBox="0 0 25 25"
    >
      <path
        d="M10.45 2.05h1.05a.5.5 0 0 1 .5.5v.024a.5.5 0 0 1-.5.5h-1.05a.5.5 0 0 1-.5-.5V2.55a.5.5 0 0 1 .5-.5zm2.05 1.024h1.05a.5.5 0 0 1 .5.5V3.6a.5.5 0 0 1-.5.5H12.5a.5.5 0 0 1-.5-.5v-.025a.5.5 0 0 1 .5-.5v-.001zM10.45 0h1.05a.5.5 0 0 1 .5.5v.025a.5.5 0 0 1-.5.5h-1.05a.5.5 0 0 1-.5-.5V.5a.5.5 0 0 1 .5-.5zm2.05 1.025h1.05a.5.5 0 0 1 .5.5v.024a.5.5 0 0 1-.5.5H12.5a.5.5 0 0 1-.5-.5v-.024a.5.5 0 0 1 .5-.5zm-2.05 3.074h1.05a.5.5 0 0 1 .5.5v.025a.5.5 0 0 1-.5.5h-1.05a.5.5 0 0 1-.5-.5v-.025a.5.5 0 0 1 .5-.5zm2.05 1.025h1.05a.5.5 0 0 1 .5.5v.024a.5.5 0 0 1-.5.5H12.5a.5.5 0 0 1-.5-.5v-.024a.5.5 0 0 1 .5-.5zm-2.05 1.024h1.05a.5.5 0 0 1 .5.5v.025a.5.5 0 0 1-.5.5h-1.05a.5.5 0 0 1-.5-.5v-.025a.5.5 0 0 1 .5-.5zm2.05 1.025h1.05a.5.5 0 0 1 .5.5v.025a.5.5 0 0 1-.5.5H12.5a.5.5 0 0 1-.5-.5v-.025a.5.5 0 0 1 .5-.5zm-2.05 1.025h1.05a.5.5 0 0 1 .5.5v.025a.5.5 0 0 1-.5.5h-1.05a.5.5 0 0 1-.5-.5v-.025a.5.5 0 0 1 .5-.5zm2.05 1.025h1.05a.5.5 0 0 1 .5.5v.024a.5.5 0 0 1-.5.5H12.5a.5.5 0 0 1-.5-.5v-.024a.5.5 0 0 1 .5-.5zm-1.656 3.074l-.82 5.946c.52.302 1.174.458 1.976.458.803 0 1.455-.156 1.975-.458l-.82-5.946h-2.311zm0-1.025h2.312c.512 0 .946.378 1.015.885l.82 5.946c.056.412-.142.817-.501 1.026-.686.398-1.515.597-2.49.597-.974 0-1.804-.199-2.49-.597a1.025 1.025 0 0 1-.5-1.026l.819-5.946c.07-.507.503-.885 1.015-.885zm.545 6.6a.5.5 0 0 1-.397-.561l.143-.999a.5.5 0 0 1 .495-.429h.74a.5.5 0 0 1 .495.43l.143.998a.5.5 0 0 1-.397.561c-.404.08-.819.08-1.222 0z"
        fill="white"
        fillRule="nonzero"
      />
    </svg>
  );
}

function iconFile() {
  return (
    <svg
      ariaHidden="true"
      focusable="false"
      className="uppy-c-icon"
      width="25"
      height="25"
      viewBox="0 0 25 25"
    >
      <g fill="white" fillRule="nonzero">
        <path d="M5.5 22a.5.5 0 0 1-.5-.5v-18a.5.5 0 0 1 .5-.5h10.719a.5.5 0 0 1 .367.16l3.281 3.556a.5.5 0 0 1 .133.339V21.5a.5.5 0 0 1-.5.5h-14zm.5-1h13V7.25L16 4H6v17z" />
        <path d="M15 4v3a1 1 0 0 0 1 1h3V7h-3V4h-1z" />
      </g>
    </svg>
  );
}

function iconText() {
  return (
    <svg
      ariaHidden="true"
      focusable="false"
      className="uppy-c-icon"
      width="25"
      height="25"
      viewBox="0 0 25 25"
    >
      <path
        d="M4.5 7h13a.5.5 0 1 1 0 1h-13a.5.5 0 0 1 0-1zm0 3h15a.5.5 0 1 1 0 1h-15a.5.5 0 1 1 0-1zm0 3h15a.5.5 0 1 1 0 1h-15a.5.5 0 1 1 0-1zm0 3h10a.5.5 0 1 1 0 1h-10a.5.5 0 1 1 0-1z"
        fill="white"
        fillRule="nonzero"
      />
    </svg>
  );
}

export const getIconByMime = (fileType) => {
  const defaultChoice = {
    color: "#838999",
    icon: iconFile(),
  };

  if (!fileType) return defaultChoice;

  const fileTypeGeneral = fileType.split("/")[0];
  const fileTypeSpecific = fileType.split("/")[1];

  // Text
  if (fileTypeGeneral === "text") {
    return {
      color: "#5a5e69",
      icon: iconText(),
    };
  }

  // Image
  if (fileTypeGeneral === "image") {
    return {
      color: "#686de0",
      icon: iconImage(),
    };
  }

  // Audio
  if (fileTypeGeneral === "audio") {
    return {
      color: "#068dbb",
      icon: iconAudio(),
    };
  }

  // Video
  if (fileTypeGeneral === "video") {
    return {
      color: "#19af67",
      icon: iconVideo(),
    };
  }

  // PDF
  if (fileTypeGeneral === "application" && fileTypeSpecific === "pdf") {
    return {
      color: "#e25149",
      icon: iconPDF(),
    };
  }

  // Archive
  const archiveTypes = [
    "zip",
    "x-7z-compressed",
    "x-rar-compressed",
    "x-tar",
    "x-gzip",
    "x-apple-diskimage",
  ];
  if (
    fileTypeGeneral === "application" &&
    archiveTypes.indexOf(fileTypeSpecific) !== -1
  ) {
    return {
      color: "#00C469",
      icon: iconArchive(),
    };
  }

  return defaultChoice;
};

export const handleUploadToS3 = async ({
  file,
  token,
  setProcessing,
  setProgress,
  onUploadAction,
}) => {
  if (!setProgress && setProcessing) {
    setProcessing(true);
  }

  const vapor = new Vapor();
  vapor.store(file, {
    baseURL: config.helix_api_url,
    bucket: config.helix_s3_bucket,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    progress: (progress) => {
      if (setProgress) {
        setProgress(progress);
      }
    },
  }).then(async (response) => {
    if (setProcessing) {
      setProcessing(true);
    }
    await onUploadAction(response);
    if (setProcessing) {
      setProcessing(false);
    }
    if (setProgress) {
      setProgress(null);
    }
  });
};

export const openExternalFile = (_file, auth) => {
  let file = _file;
  if(_file?.field_file){
    file = _file.field_file;
  }
  const win = window.open(
    `${config.api_url}/rest/file/${file.fid}/view?token=${file.file_dl_token}&oauth_token=${auth}`,
    "_blank"
  );
  if (win != null) {
    win.focus();
  }
};
