import React, { useEffect } from "react";
import { getIconByMime } from "./utils";
import { IconButton, Tooltip, Checkbox,  } from "@mui/material";
import { Close, MoreVert } from "@mui/icons-material";
import truncateString from "@uppy/utils/lib/truncateString";
import prettierBytes from "@transloadit/prettier-bytes";

import { formatError } from "../../utility";
import FileProgress from "./FileProgress";
import { IconButtonDropdown } from "common/ButtonDropdown";
import { first, toArray } from "lodash";

const renderFileName = (props) => {
  // Take up at most 2 lines on any screen
  let maxNameLength;
  // For very small mobile screens
  if (props.containerWidth <= 352) {
    maxNameLength = 35;
    // For regular mobile screens
  } else if (props.containerWidth <= 576) {
    maxNameLength = 60;
    // For desktops
  } else {
    maxNameLength = 30;
  }

  return (
    <div
      style={{
        fontSize: 12,
        lineHeight: 1.3,
        fontWeight: 500,
        marginBottom: 4,
        wordBreak: "break-all",
        wordWrap: "anywhere",
      }}
      title={props.file.meta.name}
    >
      {truncateString(props.file.meta.name, maxNameLength)}
    </div>
  );
};

const renderFileSize = (props) =>
  props.file.data.size && (
    <div
      style={{
        display: "inline-block",
        verticalAlign: "bottom",
        textTransform: "uppercase",
      }}
    >
      {prettierBytes(props.file.data.size)}
    </div>
  );

const ErrorButton = ({ file }) => {
  if (file.error) {
    const errorMessage = formatError(file.error);
    return (
      <Tooltip title={errorMessage.msg}>
        <span
          style={{
            lineHeight: "12px",
            width: "12px",
            height: "12px",
            display: "inline-block",
            verticalAlign: "middle",
            color: "white",
            backgroundColor: "gray",
            borderRadius: "50%",
            position: "relative",
            top: "-1px",
            insetInlineStart: "6px",
            fontSize: "8px",
            fontWeight: 600,
            textAlign: "center",
            cursor: "help",
          }}
          aria-label={file.error}
          data-microtip-position="bottom"
          data-microtip-size="medium"
          role="tooltip"
        >
          ?
        </span>
      </Tooltip>
    );
  }
  return null;
};

const FileInfo = (props) => {
  return (
    <div
      data-uppy-file-source={props.file.source}
      style={{ position: "relative" }}
    >
      {renderFileName(props)}
      <div
        style={{
          fontSize: 11,
          lineHeight: 1.3,
          fontWeight: "normal",
          color: "#757575",
        }}
      >
        {renderFileSize(props)}
        <ErrorButton file={props.file} />
      </div>
      <div style={{ position: "absolute", right: 0, bottom: 0 }}>
        {props.form && (
          <IconButtonDropdown
            icon={MoreVert}
            tooltip="Actions"
            size="small"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <div style={{ padding: "1.25rem", width: 250 }}>{props.form}</div>
          </IconButtonDropdown>
        )}
      </div>
    </div>
  );
};

const FilePreview = ({ file }) => {
  if (file?.preview || file?.previews) {
    if(!file?.preview){
      if(file?.previews?.thumbnail){
        file.preview = file?.previews?.thumbnail;
      }
      else{
        file.preview = first(toArray(file?.previews));
      }
    }
    return (
      <img
        style={{ objectFit: "cover", width: "100%" }}
        alt={file.name}
        src={file.preview}
      />
    );
  }

  const { color, icon } = getIconByMime(file.type);

  return (
    <div
      style={{
        height: "76px",
        maxHeight: "75%",
        position: "relative",
      }}
    >
      <span
        style={{
          width: "25px",
          height: "25px",
          zIndex: 2,
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          color,
        }}
      >
        {icon}
      </span>
      <svg
        aria-hidden="true"
        focusable="false"
        style={{
          width: "100%",
          height: "100%",
          filter: "drop-shadow(rgba(0,0,0, 0.1) 0px 1px 1px)",
        }}
        width="58"
        height="76"
        viewBox="0 0 58 76"
      >
        <rect fill="#FFF" width="58" height="76" rx="3" fillRule="evenodd" />
      </svg>
    </div>
  );
};

const FilePreviewAndLink = (props) => {
  return (
    <div
      style={{
        backgroundColor: getIconByMime(props.file.type).color,
        width: "100%",
        height: "100%",
        overflow: "hidden",
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        boxShadow: "0 0 2px 0 rgb(0 0 0 / 40%)",
        borderRadius: "3px",
      }}
    >
      <FilePreview file={props.file} />
    </div>
  );
};

const FileItem = ({
  id,
  acquirers,
  containerWidth,
  i18n,
  file,
  role,
  retryUpload,
  onRemove,
  individualCancellation,
  cancelUpload,
  form,
  showFileInfo=true,
  formData,
  setFormData,
  onChangeBasedOnType,
  selected,
  onSelect,
}) => {
  const isProcessing = file.progress.preprocess || file.progress.postprocess;
  const isUploaded =
    file.progress.uploadComplete && !isProcessing && !file.error;
  const uploadInProgressOrComplete =
    file.progress.uploadStarted || isProcessing;
  // const uploadInProgress =
  //   (file.progress.uploadStarted &&
  //     !file.progress.uploadComplete &&
  //     !file.error) ||
  //   isProcessing;
  const error = file.error || false;

  useEffect(() => {
    if (onChangeBasedOnType) {
      onChangeBasedOnType(file.type, file.id, formData, setFormData);
    }
  }, [file, onChangeBasedOnType]); // eslint-disable-line

  return (
    <div id={`uppy_${file.id}`} role={role}>
      <div style={{ position: "relative", height: 110 }}>
        {onSelect &&
          <Checkbox
            size="small"
            className={`absolute -top-2 -left-2 z-10 hover:bg-gray-light ${!selected ? 'bg-white' : 'bg-gray-light'}`}
            checked={selected.indexOf(file.id) > -1}
            onChange={onSelect ? () => onSelect(file.id) : () => {}}
          />
        }
        <div
          className={`absolute -top-2 -right-2 z-10 rounded-full text-white bg-gray-darker`}
          // style={{
          //   position: "absolute",
          //   top: -10,
          //   right: -10,
          //   zIndex: 2,
          //   borderRadius: "50%",
          //   background: "var(--color-gray-dark)",
          //   color: "white",
          // }}
        >
          <IconButton
            size="small"
            style={{ color: "white" }}
            onClick={onRemove}
            // disabled={uploadInProgress}
          >
            <Close />
          </IconButton>
        </div>
        <FilePreviewAndLink file={file} />
        {uploadInProgressOrComplete && (
          <div
            style={{
              background: "rgba(0,0,0,0.5)",
              position: "absolute",
              left: 0,
              top: 0,
              right: 0,
              bottom: 0,
            }}
          ></div>
        )}
        <FileProgress
          file={file}
          error={error}
          isUploaded={isUploaded}
          retryUpload={retryUpload}
        />
      </div>
      {showFileInfo && (
        <div style={{ paddingTop: 10 }}>
          <FileInfo
            file={file}
            id={id}
            acquirers={acquirers}
            containerWidth={containerWidth}
            i18n={i18n}
            form={form}
          />
        </div>
      )}
    </div>
  );
};

export default FileItem;
